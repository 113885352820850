const errorMessage = {
  10000: 'Unknown error',
  10001: 'Emel ini pernah didaftarkan',
  10002: 'Emel tidak sah',
  10003: 'Emel atau katalaluan tidak tepat',
  10004: 'Emel akaun belum disahkan',
  10005: 'Katalaluan sekarang tidak sah',
  10006: 'Sela masa aktiviti terlalu cepat. Tunggu sebentar dan cuba semula'
};

const errorCode = {
  'auth/email-already-in-use': 10001,
  'auth/invalid-email': 10002,
  'auth/wrong-password': 10003,
  'auth/user-not-found': 10003,
  'auth/email-not-verified': 10004,
  'invalid old password': 10005,
  'auth/too-many-requests': 10006
};

export default class UserError {
  static EMAIL_EXISTS = 10001;

  static getErrorMessage(errorId) {
    return errorMessage[errorId] ?? '';
  }

  static getErrorId(errCode) {
    return errorCode[errCode] ?? 10000;
  }
}
