import styled from 'styled-components';

export const RootDiv = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;
