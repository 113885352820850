import * as React from 'react';
import PropTypes from 'prop-types';
import msLocale from 'date-fns/locale/ms';
import { format } from 'date-fns';
import { Card, CardHeader, IconButton, Menu } from '@mui/material';
import {
  DateRange as StatementIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileDownload as DownloadIcon,
  MoreHoriz as MoreIcon,
  NoteAdd as AddIcon,
  PlaylistRemove as DeleteProductIcon
} from '@mui/icons-material';

import { IconMenuItem } from 'components/buttons';

import AddStatementDialog from './AddStatementDialog';
import SelectStatementDialog from './SelectStatementDialog';
import ChangeProductNameDialog from './ChangeProductNameDialog';
import DeleteStatementDialog from './DeleteStatementDialog';
import DeleteProductDialog from './DeleteProductDialog';
import PrintSummary from './PrintSummary';

const ProductInfo = React.forwardRef(({ name, statement }, ref) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState(null);

  React.useImperativeHandle(ref, () => ({
    showAddStatementDialog: () => {
      setDialog('addStatement');
    }
  }), []);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onCloseDialog = () => {
    setDialog(null);
  };

  const getOnCloseMenu = (dialogName) => (
    () => {
      setDialog(dialogName);
      onCloseMenu();
    }
  );

  let subHeader = 'Tiada penyata';
  const hasStatement = Number.isFinite(statement);
  if (hasStatement) {
    subHeader = format(new Date(statement), 'd MMM yyyy', { locale: msLocale });
  }

  return (
    <>
      <Card>
        <CardHeader
          title={name}
          subheader={subHeader}
          action={(
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreIcon />
            </IconButton>
          )}
        />
      </Card>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
        {
          hasStatement && (
            <IconMenuItem label="Pilih Penyata" icon={StatementIcon} onClick={getOnCloseMenu('selectStatement')} />
          )
        }
        <IconMenuItem label="Pinda Nama Produk" icon={EditIcon} onClick={getOnCloseMenu('editProductName')} />
        <IconMenuItem label="Hapus Produk" icon={DeleteProductIcon} onClick={getOnCloseMenu('deleteProduct')} />
        <IconMenuItem
          label="Tambah Penyata Baharu"
          noDivider={!hasStatement}
          icon={AddIcon}
          onClick={getOnCloseMenu('addStatement')}
        />
        {
          hasStatement && ([
            <IconMenuItem
              key="deleteStatement"
              label="Hapus Penyata Semasa"
              icon={DeleteIcon}
              onClick={getOnCloseMenu('deleteStatement')}
            />,
            <IconMenuItem
              noDivider
              key="printStatement"
              label="Muat turun Penyata Semasa"
              icon={DownloadIcon}
              onClick={getOnCloseMenu('downloadStatement')}
            />
          ])
        }
      </Menu>
      {dialog === 'selectStatement' && <SelectStatementDialog selectedDate={statement} onClose={onCloseDialog} />}
      {dialog === 'editProductName' && <ChangeProductNameDialog oldName={name} onClose={onCloseDialog} />}
      {dialog === 'addStatement' && <AddStatementDialog onClose={onCloseDialog} />}
      {dialog === 'deleteStatement' && <DeleteStatementDialog onClose={onCloseDialog} />}
      {dialog === 'deleteProduct' && <DeleteProductDialog onClose={onCloseDialog} />}
      {
        dialog === 'downloadStatement' && (
          <PrintSummary productName={name} statementDate={subHeader} onClose={onCloseDialog} />
        )
      }
    </>
  );
});

ProductInfo.displayName = 'ProductInfo';

ProductInfo.propTypes = {
  name: PropTypes.string.isRequired,
  statement: PropTypes.number
};

ProductInfo.defaultProps = {
  statement: undefined
};

export default ProductInfo;
