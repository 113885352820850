import * as React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { CssBaseline, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material';

import createTheme from './createTheme';

const theme = createTheme();

const ThemeProvider = ({ children, ...props }) => (
  // we don't use injectFirst because we already use styled-components as the style-engine for MUI
  <StyledEngineProvider injectFirst>
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <StyledThemeProvider theme={theme} {...props}>
        {children}
      </StyledThemeProvider>
    </MUIThemeProvider>
  </StyledEngineProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
