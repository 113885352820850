import * as React from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';

import { CommonDialog } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';

const AddProductButton = (_, ref) => {
  const [open, setOpen] = React.useState(false);
  const txtField = React.useRef(null);

  React.useImperativeHandle(ref, () => ({
    showDialog: (isOpen = true) => {
      setOpen(isOpen);
    }
  }), []);

  const onClose = () => {
    setOpen(false);
  };

  const onSave = (e) => {
    const productName = txtField.current?.value;
    if (productName) {
      e.preventDefault();
      ProductAction.addProduct(productName);
      onClose();
    }
  };

  return (
    <>
      <IconButton color="primary" onClick={() => setOpen(true)}>
        <AddIcon />
      </IconButton>
      <CommonDialog
        fullWidth
        open={open}
        title="Tambah Produk"
        formId="productForm"
        onClose={onClose}
        onSave={onSave}
      >
        <form id="productForm">
          <TextField required fullWidth label="Nama Produk" inputRef={txtField} />
        </form>
      </CommonDialog>
    </>
  );
};

export default React.forwardRef(AddProductButton);
