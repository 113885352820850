import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ThemeProvider from 'themes';

import { initFirebaseApp } from 'firebaseUtil/app';

import { MainPage } from 'pages/main';
import { HomePage } from 'pages/home';
import { RequireAuth, SignInPage, SignUpPage } from 'pages/authentications';
import { ProfilePage } from 'pages/profile';
import { ProductListPage } from 'pages/products';
import { ProductDetailPage } from 'pages/product-details';

import UserStore from 'stux/stores/UserStore';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// https://app.moqups.com/f1CDcVMLJ9sHNGMZ574yoax0qQXoFJXr/view/page/adf52b14c

window.onload = () => {
  initFirebaseApp();
  UserStore.init();

  ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <React.StrictMode>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<SignInPage />} />
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/" element={<MainPage />}>
              <Route index element={<HomePage />} />
              <Route
                path="profile"
                element={(
                  <RequireAuth>
                    <ProfilePage />
                  </RequireAuth>
                )}
              />
              <Route path="products">
                <Route
                  index
                  element={(
                    <RequireAuth>
                      <ProductListPage />
                    </RequireAuth>
                  )}
                />
                <Route
                  path=":productId"
                  element={(
                    <RequireAuth>
                      <ProductDetailPage />
                    </RequireAuth>
                  )}
                />
              </Route>
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
