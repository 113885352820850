import * as React from 'react';
import PropTypes from 'prop-types';
import { Add as AddIcon } from '@mui/icons-material';
import { Divider, IconButton, Typography } from '@mui/material';

import { InfoLabel } from 'components/typography';

import { Header } from './listHeaderStyles';

const ListHeader = ({ title, btnRef, btnSX, tooltip, onAddItem, children }) => (
  <div>
    <Header>
      {
        tooltip ? (
          <InfoLabel
            label={title}
            tooltip={tooltip}
            labelSX={{ whiteSpace: 'pre-wrap' }}
            labelProps={{
              color: 'primary',
              fontWeight: 'bold'
            }}
          />
        ) : (
          <Typography color="primary" fontWeight="bold">{title}</Typography>
        )
      }
      {
        !!onAddItem && (
          <IconButton color="primary" ref={btnRef} sx={btnSX} onClick={onAddItem}>
            <AddIcon />
          </IconButton>
        )
      }
      {children}
    </Header>
    <Divider variant="thick" />
  </div>
);

ListHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onAddItem: PropTypes.func,
  children: PropTypes.element,
  btnSX: IconButton.propTypes.sx,
  tooltip: PropTypes.string,
  btnRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLButtonElement) })
  ])
};

ListHeader.defaultProps = {
  tooltip: undefined,
  onAddItem: undefined,
  children: null,
  btnSX: {},
  btnRef: undefined
};

export default ListHeader;
