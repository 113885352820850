import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import { CommonDialog } from 'pages/commons';

const FixedCostDialog = ({ title, oldData, onSave, onClose }) => {
  const itemTxtField = React.useRef(null);
  const priceTxtField = React.useRef(null);

  const onSaveValue = (e) => {
    const name = itemTxtField.current.value;
    const price = Number(priceTxtField.current.value);
    if (name && price) {
      e.preventDefault();
      if (price) {
        if (!oldData || (oldData && (name !== oldData.name || price !== oldData.value))) {
          onSave({
            name,
            value: price
          });
        }
        onClose();
      }
    }
  };

  return (
    <CommonDialog
      open
      fullWidth
      title={title}
      formId="fixedCost"
      onClose={onClose}
      onSave={onSaveValue}
    >
      <form id="fixedCost">
        <TextField
          required
          fullWidth
          name="itemName"
          label="Nama Item"
          defaultValue={oldData?.name ?? ''}
          inputRef={itemTxtField}
        />
        <TextField
          required
          fullWidth
          name="price"
          type="number"
          label="Nilai (RM)"
          defaultValue={oldData?.value ?? ''}
          inputRef={priceTxtField}
          sx={{ mt: 2 }}
        />
      </form>
    </CommonDialog>
  );
};

FixedCostDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  oldData: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number
  })
};

FixedCostDialog.defaultProps = {
  oldData: undefined
};

export default FixedCostDialog;
