import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

import ProductStore from 'stux/stores/ProductStore';
import ProfileStore from 'stux/stores/ProfileStore';
import { useStux } from 'stux/utils';

const ProcessingOverlay = () => {
  const [show, setShow] = React.useState(false);
  const { isProcessing: productProcessing } = useStux(ProductStore, 'isProcessing');
  const { isProcessing: profileProcessing } = useStux(ProfileStore, 'isProcessing');
  const timerId = React.useRef();

  React.useEffect(() => (
    () => {
      window.clearTimeout(timerId.current);
    }
  ), []);

  React.useEffect(() => {
    window.clearTimeout(timerId.current);

    if (productProcessing || profileProcessing) {
      timerId.current = window.setTimeout(() => {
        setShow(true);
      }, 300);
    } else {
      timerId.current = undefined;
      setShow(false);
    }
  }, [productProcessing, profileProcessing]);

  return (
    <Backdrop
      open={show}
      sx={{
        left: 'unset',
        zIndex: 2,
        width: {
          xs: '100vw',
          sm: 'calc(100vw - 300px)' // minus the drawer width
        }
      }}
    >
      <CircularProgress disableShrink color="warning" size={80} />
    </Backdrop>
  );
};

export default ProcessingOverlay;
