import * as React from 'react';
import PropTypes from 'prop-types';
import roundedToFixed from 'round-tofixed';

import { NumberTextField } from 'components/textfield';

import { GridItemCard, GridItemContent, ValueLabel } from 'pages/commons';

const SalePerUnit = ({ markup, costPerUnit, salePerUnit, onChange }) => (
  <GridItemCard title="Kiraan Harga Jualan Seunit">
    <GridItemContent>
      <NumberTextField
        fullWidth
        shrinkLabel
        label="Mark-Up"
        endAdornment="%"
        defaultValue={markup}
        onChange={onChange}
      />
    </GridItemContent>
    <GridItemContent>
      <NumberTextField
        readOnly
        fullWidth
        shrinkLabel
        label="Kos Seunit"
        startAdornment="RM"
        value={roundedToFixed(costPerUnit, 2).toFixed(2)}
      />
      <ValueLabel label="Harga Jualan Seunit" value={salePerUnit} />
    </GridItemContent>
  </GridItemCard>
);

SalePerUnit.propTypes = {
  markup: PropTypes.number.isRequired,
  costPerUnit: PropTypes.number.isRequired,
  salePerUnit: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SalePerUnit;
