import * as React from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import { CostSummaryCard } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';

import CostCard from './CostCard';
import FixedCostDialog from './FixedCostDialog';
import VariableCostDialog from './VariableCostDialog';
import { useProductDetail } from '../ProductDetailContext';

const tooltips = {
  materialCost: 'Kos Bahan Langsung\n\nHarga bahan mentah yang terlibat dalam pengeluaran '
                + 'produk yang dihasilkan',
  labourCost: 'Kos Buruh Langsung\n\nGaji pekerja yang terlibat dalam pengeluaran '
              + 'produk yang dihasilkan'
};

const titles = {
  materialCost: {
    desktop: 'Kos Bahan Langsung (Kos Berubah)',
    mobile: 'Kos Bahan Langsung\n(Kos Berubah)'
  },
  labourCost: {
    desktop: 'Kos Buruh Langsung (Kos Berubah)',
    mobile: 'Kos Buruh Langsung\n(Kos Berubah)'
  }
};

const CostStatement = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [dialog, setDialog] = React.useState(undefined);
  const materialAddButton = React.useRef(null);
  const editData = React.useRef(undefined);
  const {
    materialCost,
    labourCost,
    overheadCost,
    nonProductionCost,
    otherVariableCost,
    otherFixedCost,
    variableCost,
    fixedCost,
    currentStatement: {
      foreignMaterialCosts = [],
      labourCosts = [],
      productionOverheadCosts = [],
      nonProductionCosts = [],
      otherVariableCosts = [],
      otherFixedCosts = []
    }
  } = useProductDetail();

  const onClose = () => {
    editData.current = undefined;
    setDialog(undefined);
  };

  const getSaveHandler = (costType) => (
    (data) => {
      if (!editData.current) {
        ProductAction.addCost(costType, data);
      } else {
        const { id } = editData.current;
        const newData = { id, ...data };
        ProductAction.updateCost(costType, id, newData);
      }
    }
  );

  const getEditItemHandler = (dialogName) => (
    (data) => {
      editData.current = data;
      setDialog(dialogName);
    }
  );

  const getDeleteItemHandler = (costType) => (
    (costId) => {
      ProductAction.deleteCost(costType, costId);
    }
  );

  const titleKey = matches ? 'desktop' : 'mobile';
  return (
    <>
      <Grid container spacing={2}>
        <CostCard
          title={titles.materialCost[titleKey]}
          total={materialCost}
          btnRef={materialAddButton}
          data={foreignMaterialCosts}
          tooltip={tooltips.materialCost}
          onAddItem={() => setDialog('foreignMaterial')}
          onEditItem={getEditItemHandler('foreignMaterial')}
          onDeleteItem={getDeleteItemHandler('foreignMaterialCosts')}
        />
        <CostCard
          title={titles.labourCost[titleKey]}
          total={labourCost}
          data={labourCosts}
          tooltip={tooltips.labourCost}
          onAddItem={() => setDialog('labour')}
          onEditItem={getEditItemHandler('labour')}
          onDeleteItem={getDeleteItemHandler('labourCosts')}
        />
        <CostCard
          title="Lain-lain Kos Berubah"
          total={otherVariableCost}
          data={otherVariableCosts}
          onAddItem={() => setDialog('otherVariable')}
          onEditItem={getEditItemHandler('otherVariable')}
          onDeleteItem={getDeleteItemHandler('otherVariableCosts')}
        />
        <CostCard
          title="Kos Overhed Pengeluaran (Kos Tetap)"
          total={overheadCost}
          data={productionOverheadCosts}
          onAddItem={() => setDialog('overhead')}
          onEditItem={getEditItemHandler('overhead')}
          onDeleteItem={getDeleteItemHandler('productionOverheadCosts')}
        />
        <CostCard
          title="Kos Overhed Operasi (Kos Tetap)"
          total={nonProductionCost}
          data={nonProductionCosts}
          onAddItem={() => setDialog('nonProduction')}
          onEditItem={getEditItemHandler('nonProduction')}
          onDeleteItem={getDeleteItemHandler('nonProductionCosts')}
        />
        <CostCard
          title="Lain-lain Kos Tetap"
          total={otherFixedCost}
          data={otherFixedCosts}
          onAddItem={() => setDialog('otherFixed')}
          onEditItem={getEditItemHandler('otherFixed')}
          onDeleteItem={getDeleteItemHandler('otherFixedCosts')}
        />
        <CostSummaryCard variableCost={variableCost} fixedCost={fixedCost} variant="dark" />
      </Grid>
      {
        dialog === 'foreignMaterial' && (
          <VariableCostDialog
            showOwnProductOption
            title="Kos Bahan Langsung"
            oldData={editData.current}
            onSave={getSaveHandler('foreignMaterialCosts')}
            onClose={onClose}
          />
        )
      }
      {
        dialog === 'labour' && (
          <VariableCostDialog
            title="Kos Buruh Langsung"
            oldData={editData.current}
            onSave={getSaveHandler('labourCosts')}
            onClose={onClose}
          />
        )
      }
      {
        dialog === 'otherVariable' && (
          <VariableCostDialog
            title="Lain-lain Kos Berubah"
            oldData={editData.current}
            onSave={getSaveHandler('otherVariableCosts')}
            onClose={onClose}
          />
        )
      }
      {
        dialog === 'overhead' && (
          <FixedCostDialog
            title="Kos Overhed Pengeluaran"
            oldData={editData.current}
            onSave={getSaveHandler('productionOverheadCosts')}
            onClose={onClose}
          />
        )
      }
      {
        dialog === 'nonProduction' && (
          <FixedCostDialog
            title="Kos Overhed Operasi"
            oldData={editData.current}
            onSave={getSaveHandler('nonProductionCosts')}
            onClose={onClose}
          />
        )
      }
      {
        dialog === 'otherFixed' && (
          <FixedCostDialog
            title="Lain-lain Kos Tetap"
            oldData={editData.current}
            onSave={getSaveHandler('otherFixedCosts')}
            onClose={onClose}
          />
        )
      }
    </>
  );
};

export default CostStatement;
