import * as React from 'react';
import PropTypes from 'prop-types';
import msLocale from 'date-fns/locale/ms';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { Badge, TextField } from '@mui/material';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/lab';

import { dateFromString } from 'utils/formatter';

import { CommonDialog } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';
import ProductStore from 'stux/stores/ProductStore';

const SelectStatementDialog = ({ selectedDate, onClose }) => {
  const statementDates = React.useRef(ProductStore.state.statements.map(({ date }) => date));
  const [value, setValue] = React.useState(dateFromString(format(selectedDate, 'yyyy-MM-dd')));

  const onSave = () => {
    const date = format(value, 'yyyy-MM-dd');
    const dateTime = dateFromString(date).getTime();
    const statement = ProductStore.state.statements.find((val) => val.date === dateTime);
    if (statement) {
      ProductAction.selectStatement(statement.id);
    }
    onClose();
  };

  return (
    <CommonDialog
      open
      title="Pilih Penyata"
      saveLabel="Pilih"
      contentSX={{ p: 0 }}
      onClose={onClose}
      onSave={onSave}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={msLocale}>
        <StaticDatePicker
          disableHighlightToday
          displayStaticWrapperAs="desktop"
          value={value}
          renderInput={(params) => <TextField {...params} />}
          renderDay={(date, _value, dProps) => {
            const d = dateFromString(format(date, 'yyyy-MM-dd')).getTime();
            const isEnabled = !dProps.outsideCurrentMonth && statementDates.current.includes(d);

            if (!isEnabled) {
              return <PickersDay {...dProps} disabled />;
            }

            return (
              <Badge
                key={date.toString()}
                variant="dot"
                color="secondary"
                overlap="circular"
              >
                <PickersDay
                  {...dProps}
                  selected={d === value.getTime()}
                  sx={{ color: 'secondary.main', border: '1px solid' }}
                />
              </Badge>
            );
          }}
          onChange={setValue}
        />
      </LocalizationProvider>
    </CommonDialog>
  );
};

SelectStatementDialog.propTypes = {
  selectedDate: PropTypes.number,
  onClose: PropTypes.func.isRequired
};

SelectStatementDialog.defaultProps = {
  selectedDate: 0
};

export default SelectStatementDialog;
