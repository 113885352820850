import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@mui/material';

import { EmptyListContainer, EmptyListContent, EmptyListIcon } from './emptyListStyles';

const EmptyList = ({ message1, message2, noLink, onClickLink, ...props }) => (
  <EmptyListContainer {...props}>
    <EmptyListIcon />
    <Typography variant="subtitle2">{message1}</Typography>
    {
      !noLink && (
        <EmptyListContent>
          <Typography variant="subtitle2" component="span">
            {`Klik ${message2}`}
            &nbsp;
          </Typography>
          <Link
            type="button"
            component="button"
            underline="hover"
            variant="subtitle2"
            onClick={onClickLink}
          >
            <strong>di sini</strong>
          </Link>
          <Typography variant="subtitle2" component="span">
            &nbsp;untuk mula.
          </Typography>
        </EmptyListContent>
      )
    }
  </EmptyListContainer>
);

EmptyList.propTypes = {
  ...Box.propTypes,
  message1: PropTypes.string,
  message2: PropTypes.string,
  noLink: PropTypes.bool,
  onClickLink: PropTypes.func
};

EmptyList.defaultProps = {
  message1: '',
  message2: '',
  noLink: false,
  onClickLink: () => {}
};

export default EmptyList;
