import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';

import { formatCurrency } from 'utils/formatter';

import DiscountLabel from './DiscountLabel';
import { Card } from './styles';

const SmallItemCard = ({ value, comment, unit, isCurrency, discount }) => {
  const realValue = value - (value * (discount / 100));
  return (
    <Grid item xs>
      <Card $shouldCenter>
        <Typography variant="h4" color="primary.light">
          { isCurrency ? formatCurrency(realValue) : `${realValue} ${unit}`}
        </Typography>
        <Typography sx={{ mb: discount ? 0.5 : 0 }}>{comment}</Typography>
        <DiscountLabel size="small" value={value} discount={discount} />
      </Card>
    </Grid>
  );
};

SmallItemCard.propTypes = {
  value: PropTypes.number,
  comment: PropTypes.string.isRequired,
  unit: PropTypes.string,
  isCurrency: PropTypes.bool,
  discount: PropTypes.number
};

SmallItemCard.defaultProps = {
  value: 0,
  isCurrency: false,
  unit: 'unit',
  discount: 0
};

export default SmallItemCard;
