import styled from 'styled-components';
import { Box } from '@mui/material';
import { SentimentVeryDissatisfied as EmptyIcon } from '@mui/icons-material';

export const EmptyListContainer = styled(Box)`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const EmptyListIcon = styled(EmptyIcon)`
  opacity: 0.3;
  font-size: 8rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const EmptyListContent = styled.div`
  text-align: center;
`;
