import * as React from 'react';

import { Card, CardContent, Typography } from '@mui/material';
import { Search, AutoStories } from '@mui/icons-material';

const EGuide = () => (
  <Card
    variant="outlined"
    sx={{
      mb: '1rem !important',
      display: 'flex',
      flex: 1,
      width: '100%',
      maxWidth: '1280px',
      border: 'none',
      flexDirection: { xs: 'column', sm: 'row' }
    }}
  >
    <CardContent sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography variant="h5" fontWeight="bold">Accounting and Costing e-Guidebook</Typography>
      <Typography variant="subtitle1">
        This e-guidebook is also provided for your reference and easy revision. Whenever in doubt,
        just grab the e-guidebook accessible for you handily from your device and get the
        answer in an instant.
      </Typography>
    </CardContent>
    <CardContent
      sx={{
        position: 'relative',
        overflow: 'hidden',
        maxWidth: '100vw',
        display: { xs: 'flex', sm: 'block' },
        justifyContent: { xs: 'center', sm: undefined }
      }}
    >
      <AutoStories
        sx={{
          color: 'primary.light',
          mr: { xs: 12, sm: 25 },
          fontSize: '15.5rem',
          transform: 'rotate(-10deg)'
        }}
      />
      <Search
        sx={{
          position: 'absolute',
          right: { xs: 10, sm: 0 },
          bottom: 0,
          color: 'salmon',
          fontSize: 200
        }}
      />
    </CardContent>
  </Card>
);

export default EGuide;
