import * as React from 'react';
import { ArrowCircleLeftRounded as LogoutIcon } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import { Slide } from 'components/transitions';

import UserAction from 'stux/actions/UserAction';

const SignOutListItem = () => {
  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItemButton onClick={() => setOpen(true)}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'alternative.main' }}>
            <LogoutIcon fontSize="large" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Log Keluar" />
      </ListItemButton>
      <Dialog fullWidth open={open} TransitionComponent={Slide} onClose={onClose}>
        <DialogTitle>
          Log Keluar
        </DialogTitle>
        <DialogContent>
          Anda pasti untuk log keluar?
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button color="error" onClick={onClose}>
            <strong>Tidak</strong>
          </Button>
          <Button color="primary" onClick={UserAction.signOut}>
            <strong>Saya Pasti</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignOutListItem;
