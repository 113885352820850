import * as React from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack } from '@mui/material';

import { InfoLabel } from 'components/typography';

const SummaryContent = ({ title, tooltip, children }) => (
  <Stack spacing={1.5}>
    <Divider />
    <InfoLabel
      label={title}
      tooltip={tooltip}
      labelProps={{ color: 'primary' }}
    />
    {children}
  </Stack>
);

SummaryContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string
};

SummaryContent.defaultProps = {
  tooltip: ''
};

export default SummaryContent;
