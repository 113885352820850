import styled from 'styled-components';

import { NumberTextField } from 'components/textfield';

export const LongLabelNumberTextfield = styled(NumberTextField)`
  .MuiFormLabel-root {
    white-space: break-spaces;
  }

  label + .MuiInput-root {
    margin-top: ${({ theme }) => theme.spacing(4)};
  }
`;
