import * as React from 'react';
import PropTypes from 'prop-types';

import { CommonDialog } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';

const DeleteProductDialog = ({ onClose }) => {
  const [step, setStep] = React.useState(0);
  const messages = React.useRef([
    'Anda pasti untuk menghapuskan produk ini?',
    'Selepas produk ini dihapuskan, anda tidak akan dapat memperolehinya kembali. '
    + 'Adakah anda pasti?'
  ]);
  return (
    <CommonDialog
      open
      title="Hapus Produk"
      cancelLabel="Tidak"
      saveLabel="Ya"
      onClose={onClose}
      onSave={() => {
        if (step === 1) {
          ProductAction.deleteProduct();
          onClose();
        } else {
          setStep((prev) => prev + 1);
        }
      }}
    >
      {messages.current[step]}
    </CommonDialog>
  );
};

DeleteProductDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DeleteProductDialog;
