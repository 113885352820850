import styled from 'styled-components';
import { Stack } from '@mui/material';

export const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const Header = styled(Stack)`
  max-width: 1280px;
  padding: ${({ theme }) => theme.spacing(7)};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: -1px;
`;

export const SlantedDiv = styled.div`
  height: 50px;
  width: 100%;
  margin-left: -1px;
  background:
    linear-gradient(
      to right top,
      rgba(12, 89, 207, 0) 0%,
      rgba(12, 89, 207, 0) 50%,
      rgba(12, 89, 207, 1) calc(50% + 1px),
      rgba(12, 89, 207, 1) 100%
    );
`;

export const PointNumber = styled.div`
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: -1.25rem;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.25rem;
  border-left: solid 2px rgba(255, 255, 255, 0.6);
  border-bottom: solid 2px rgba(255, 255, 255, 0.5);
  border-top: solid 1px rgba(255, 255, 255, 0.5);
  background-color: ${({ theme }) => theme.palette.primary.main};
`;
