import * as React from 'react';
import roundToFixed from 'round-tofixed';
import { Button, Grid } from '@mui/material';

import ProductAction from 'stux/actions/ProductAction';

import CostPerUnit from './CostPerUnit';
import SalePerUnit from './SalePerUnit';
import TotalSale from './TotalSale';
// import NetSale from './NetSale';
import { useProductDetail } from '../ProductDetailContext';

const SalePrice = () => {
  const {
    variableCost,
    fixedCost,
    totalSale,
    unitCost,
    unitPrice,
    currentStatement: {
      tax = 0,
      delivery = 0,
      markup = 0,
      discount = 0,
      totalOrder = 0
    }
  } = useProductDetail();
  const totalCost = variableCost + fixedCost;
  const [costPerUnit, setCostPerUnit] = React.useState(0);
  const [salePerUnit, setSalePerUnit] = React.useState(0);
  const [calcTotalOrder, setCalcTotalOrder] = React.useState(0);
  const [calcMarkup, setCalcMarkup] = React.useState(0);
  const [calcDiscount, setCalcDiscount] = React.useState(0);
  const [calcTotalSale, setCalcTotalSale] = React.useState(0);
  const [calcTax, setCalcTax] = React.useState(0);
  const [calcDelivery, setCalcDelivery] = React.useState(0);
  const [totalOrderError, setTotalOrderError] = React.useState('');
  const keySuffix = React.useRef(0);

  React.useEffect(() => {
    // we have to update the children key when the currentStatement data changed
    // so that, all the textfields in the child components will be updated..
    // the reason it'll not be updated if not re-assign a new key is that,
    // some of them are uncontrolled (i.e: using 'defaultValue' prop instead of 'value' prop)..
    // they are uncontrolled so that the component can be empty if the value is 0
    // when focus (for a better user experience)
    keySuffix.current += 1;
    setCostPerUnit(unitCost);
    setSalePerUnit(unitPrice);
    setCalcTotalOrder(totalOrder);
    setCalcMarkup(markup);
    setCalcDiscount(discount);
    setCalcTotalSale(totalSale);
    setCalcTax(tax);
    setCalcDelivery(delivery);
  }, [unitCost, unitPrice, totalOrder, markup, discount, totalSale, tax, delivery]);

  const onSave = () => {
    if (!calcTotalOrder) {
      setTotalOrderError('Kuantiti tidak munasabah');
      return;
    }

    if (calcTotalOrder !== totalOrder || calcMarkup !== markup
      || calcDiscount !== discount
      || calcTax !== tax || calcDelivery !== delivery
    ) {
      ProductAction.updateSale({
        totalOrder: calcTotalOrder,
        markup: calcMarkup,
        discount: calcDiscount,
        tax: calcTax,
        delivery: calcDelivery
      });
    }
  };

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        sx={{ position: 'sticky', top: { xs: '62px', md: '72px' }, zIndex: 1 }}
        onClick={onSave}
      >
        Simpan
      </Button>
      <Grid container spacing={2}>
        <CostPerUnit
          key={`unitCost-${keySuffix.current}`}
          totalCost={totalCost}
          totalOrder={calcTotalOrder}
          costPerUnit={costPerUnit}
          errorText={totalOrderError}
          onChange={(val) => {
            setCalcTotalOrder(val);

            const newCostPerUnit = val ? totalCost / val : 0;
            const newSalePerUnit = roundToFixed(
              ((calcMarkup * 0.01) * newCostPerUnit) + newCostPerUnit,
              2
            );
            setCostPerUnit(newCostPerUnit);
            setSalePerUnit(newSalePerUnit);
            setCalcTotalSale(newSalePerUnit * val);
          }}
        />
        <SalePerUnit
          key={`unitSale-${keySuffix.current}`}
          markup={calcMarkup}
          costPerUnit={costPerUnit}
          salePerUnit={salePerUnit}
          onChange={(val) => {
            setCalcMarkup(val);

            const newSalePerUnit = roundToFixed(((val * 0.01) * costPerUnit) + costPerUnit, 2);
            setSalePerUnit(newSalePerUnit);
            setCalcTotalSale(newSalePerUnit * calcTotalOrder);
          }}
        />
        <TotalSale
          key={`totalSale-${keySuffix.current}`}
          discount={calcDiscount}
          unitPrice={salePerUnit}
          totalSale={calcTotalSale}
          onChange={setCalcDiscount}
        />
        {/* <NetSale
          key={`netSale-${keySuffix.current}`}
          tax={calcTax}
          delivery={calcDelivery}
          discount={calcDiscount}
          totalSale={calcTotalSale}
          onChangeTax={setCalcTax}
          onChangeDelivery={setCalcDelivery}
        /> */}
      </Grid>
    </>
  );
};

export default React.memo(SalePrice);
