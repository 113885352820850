import * as React from 'react';
import { Grid } from '@mui/material';

import { CostSummaryCard } from 'pages/commons';

import SmallItemCard from './SmallItemCard';
import LargeItemCard from './LargeItemCard';
import DetailItemCard from './DetailItemCard';
import { useProductDetail } from '../ProductDetailContext';

const tooltips = {
  totalSale: 'Jumlah Jualan\n\nJumlah hasil yang diperolehi untuk tempoh tertentu '
             + '(setiap hari atau setiap bulan).\n\nPengiraan:\nHarga jualan seunit '
             + 'produk atau perkhidmatan (setelah diskaun) x Bilangan unit yang dijual',
  totalCost: 'Jumlah Kos\n\nJumlah bahan mentah atau sebarang aktiviti yang berkaitan '
             + 'untuk menghasilkan jualan dan operasi perniagaan samada secara '
             + 'langsung atau tidak langsung'
};

const ProductSummary = () => {
  const {
    currentStatement,
    variableCost,
    fixedCost,
    unitPrice,
    totalSale
  } = useProductDetail();

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Grid container spacing={2}>
          <SmallItemCard
            comment="Unit Jualan (Permintaan)"
            value={currentStatement?.totalOrder}
          />
          <SmallItemCard
            isCurrency
            comment="Harga Jualan Seunit"
            value={unitPrice}
            discount={currentStatement?.discount}
          />
        </Grid>
      </Grid>
      <LargeItemCard
        title="Jumlah Jualan"
        value={totalSale}
        tooltip={tooltips.totalSale}
        discount={currentStatement?.discount}
      />
      <CostSummaryCard
        tooltip={tooltips.totalCost}
        variableCost={variableCost}
        fixedCost={fixedCost}
      />
      <DetailItemCard
        variableCost={variableCost}
        fixedCost={fixedCost}
        unitPrice={unitPrice}
        totalSale={totalSale}
        totalOrder={currentStatement?.totalOrder ?? 0}
        discount={currentStatement?.discount}
      />
    </Grid>
  );
};

export default ProductSummary;
