import * as React from 'react';
import PropTypes from 'prop-types';

// the below is the workaround to fix warning of Failed to parse source map
// see: https://github.com/eKoopmans/html2pdf.js/issues/570
// import html2pdf from 'html2pdf.js';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import { format } from 'date-fns';
import { Backdrop, CircularProgress } from '@mui/material';

import ProductStore from 'stux/stores/ProductStore';

import { calculateProductDetail } from '../ProductDetailContext';
import { getPrintHtml } from './printHtml';
import { getBusinessProfile } from './getProfile';

const PrintSummary = ({ productName, statementDate, onClose }) => {
  React.useEffect(async () => {
    const { currentStatement } = ProductStore.state;
    if (!currentStatement) return;

    const businessProfile = await getBusinessProfile();
    const calculatedDetail = calculateProductDetail(currentStatement);

    const printStr = getPrintHtml(
      productName,
      statementDate,
      currentStatement,
      calculatedDetail,
      businessProfile
    );
    const printHtml = new DOMParser().parseFromString(printStr, 'text/html').documentElement;

    const titleName = productName.split(' ').join('-');
    const titleDate = format(new Date(currentStatement.statementDate), 'yyyy-MM-dd');
    const opt = {
      margin: 1, // 1 inch margin
      filename: `${titleName}_${titleDate}.pdf`,
      pagebreak: { mode: ['avoid-all', 'css'], after: ['#separator'] },
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'in',
        format: 'a4',
        orientation: 'portrait'
      }
    };

    html2pdf()
      .set(opt)
      .from(printHtml)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const { pageSize, getNumberOfPages } = pdf.internal;
        const totalPages = getNumberOfPages();
        [...Array(totalPages).keys()].forEach((i) => {
          pdf.setPage(i + 1); // set this to the page where page number is non-zero-based index
          pdf.setFontSize(10);
          pdf.text(
            `${i + 1}/${totalPages}`,
            pageSize.getWidth() / 2.05, // center of the page
            pageSize.getHeight() - 0.5
          );
        });

        // open the pdf in a new tab for the user to view, download or print..
        // this also can avoid the problem with chrome which limits the number of downloads
        // for the similar file multiple times..
        // see: https://stackoverflow.com/questions/52917030/chrome-blocks-download-at-second-attempt
        window.open(pdf.output('bloburl'), '_blank');
      })
      .then(() => {
        onClose();
      });
  }, []);

  return (
    <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

PrintSummary.propTypes = {
  productName: PropTypes.string.isRequired,
  statementDate: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default PrintSummary;
