import { dispatchAction } from '../dispatchers/AppDispatcher';

export default class ProductAction {
  static INIT_DB = Symbol('initDB');
  static RESET = Symbol('reset');
  static ADD_PRODUCT = Symbol('addProduct');
  static GET_PRODUCTS = Symbol('getProducts');
  static GET_PRODUCT_DETAIL = Symbol('getProductDetail');
  static CHANGE_PRODUCT_NAME = Symbol('changeProductName');
  static ADD_STATEMENT = Symbol('addStatement');
  static ADD_COST = Symbol('addCost');
  static DELETE_COST = Symbol('deleteCost');
  static UPDATE_COST = Symbol('updateCost');
  static UPDATE_SALE = Symbol('updateSale');
  static SELECT_STATEMENT = Symbol('selectStatement');
  static DELETE_STATEMENT = Symbol('deleteStatement');
  static DELETE_PRODUCT = Symbol('deleteProduct');

  static initDB(userId) {
    dispatchAction(ProductAction.INIT_DB, { userId });
  }

  static reset() {
    dispatchAction(ProductAction.RESET);
  }

  static getProducts() {
    dispatchAction(ProductAction.GET_PRODUCTS);
  }

  static addProduct(productName) {
    dispatchAction(ProductAction.ADD_PRODUCT, { productName });
  }

  static getProductDetails(productId) {
    dispatchAction(ProductAction.GET_PRODUCT_DETAIL, { productId });
  }

  static changeProductName(newName) {
    dispatchAction(ProductAction.CHANGE_PRODUCT_NAME, { newName });
  }

  static addStatement(statementDate) {
    dispatchAction(ProductAction.ADD_STATEMENT, { statementDate });
  }

  static addCost(costType, costData) {
    dispatchAction(ProductAction.ADD_COST, { costType, costData });
  }

  static deleteCost(costType, costId) {
    dispatchAction(ProductAction.DELETE_COST, { costType, costId });
  }

  static updateCost(costType, costId, costData) {
    dispatchAction(ProductAction.UPDATE_COST, { costType, costId, costData });
  }

  static updateSale(saleData) {
    dispatchAction(ProductAction.UPDATE_SALE, { saleData });
  }

  static selectStatement(statementId) {
    dispatchAction(ProductAction.SELECT_STATEMENT, { statementId });
  }

  static deleteStatement() {
    dispatchAction(ProductAction.DELETE_STATEMENT);
  }

  static deleteProduct() {
    dispatchAction(ProductAction.DELETE_PRODUCT);
  }
}
