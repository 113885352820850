import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Grid, List, Paper, Typography } from '@mui/material';

import { ListHeader } from 'pages/commons';

import { formatCurrency } from 'utils/formatter';

import CostListItem from './CostListItem';

const CostCard = ({ title, total, tooltip, btnRef, data, onAddItem, onEditItem, onDeleteItem }) => (
  <Grid item xs={12}>
    <Paper sx={{ p: 2 }}>
      <ListHeader
        btnRef={btnRef}
        btnSX={{ mr: (theme) => theme.spacing(-1) }}
        title={title}
        tooltip={tooltip}
        onAddItem={onAddItem}
      />
      <List sx={{ pt: 0, pb: 1.5 }}>
        {
          data.length ? (
            data.map(({ ...item }) => (
              <CostListItem {...item} key={item.id} onEdit={onEditItem} onDelete={onDeleteItem} />
            ))
          ) : (
            <Typography sx={{ mt: 1, textAlign: 'center' }}>
              Tiada rekod untuk dipaparkan
            </Typography>
          )
        }
      </List>
      {
        !!data.length && (
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Chip
              color="primary"
              sx={{ pt: 0.25, fontSize: '1rem', fontWeight: 'bold' }}
              label={formatCurrency(total)}
            />
          </Box>
        )
      }
    </Paper>
  </Grid>
);

CostCard.propTypes = {
  ...ListHeader.propTypes,
  total: PropTypes.number.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      quantity: PropTypes.number,
      unit: PropTypes.string
    })
  )
};

CostCard.defaultProps = {
  ...ListHeader.defaultProps,
  data: []
};

export default CostCard;
