import * as React from 'react';
import PropTypes from 'prop-types';
import { CheckBoxOutlineBlank as CheckboxBox } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import { InfoLabel } from 'components/typography';
import { NumberTextField } from 'components/textfield';

import { CommonDialog } from 'pages/commons';

import { formatCurrency } from 'utils/formatter';

import { TotalCostBox } from './styles';

const tooltip = 'Bahan Keluaran Sendiri\n\nSebarang bahan dari produk keluaran sendiri '
                + 'yang digunakan untuk menghasilkan produk ini';

const VariableCostDialog = ({
  title,
  subtitle,
  showOwnProductOption,
  oldData,
  onSave,
  onClose
}) => {
  const itemTxtField = React.useRef(null);
  const unitTxtField = React.useRef(null);

  const [unitPrice, setUnitPrice] = React.useState(oldData?.value ?? 0);
  const [quantity, setQuantity] = React.useState(oldData?.quantity ?? 0);
  const [total, setTotal] = React.useState(0);
  const [isOwnProduct, setIsOwnProduct] = React.useState(oldData?.isOwnProduct ?? false);

  const onSaveValue = (e) => {
    const name = itemTxtField.current.value;
    const unit = unitTxtField.current.value;
    if (name && unit) {
      e.preventDefault();
      const newQuantity = Number(quantity);
      const newUnitPrice = Number(unitPrice);
      if (newQuantity && newUnitPrice) {
        if (!oldData || (oldData && (
          name !== oldData.name
          || unit !== oldData.unit
          || isOwnProduct !== oldData.isOwnProduct
          || newQuantity !== oldData.quantity
          || newUnitPrice !== oldData.value
        ))) {
          onSave({
            name,
            unit,
            isOwnProduct,
            quantity: newQuantity,
            value: newUnitPrice
          });
        }
        onClose();
      }
    }
  };

  React.useEffect(() => {
    setTotal(Number(unitPrice) * Number(quantity));
  }, [unitPrice, quantity]);

  return (
    <CommonDialog
      open
      fullWidth
      title={title}
      subtitle={subtitle}
      formId="variableCost"
      onClose={onClose}
      onSave={onSaveValue}
    >
      <form id="variableCost">
        <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="itemName"
              label="Nama Item"
              defaultValue={oldData?.name ?? ''}
              inputRef={itemTxtField}
            />
          </Grid>
          {
            showOwnProductOption && (
              <Grid item xs={12}>
                <FormControlLabel
                  checked={isOwnProduct}
                  onChange={(e) => setIsOwnProduct(e.target.checked)}
                  control={(
                    <Checkbox
                      color="primary"
                      icon={<CheckboxBox sx={{ color: 'primary.light' }} />}
                    />
                  )}
                  label={(
                    <InfoLabel
                      label="Bahan adalah dari produk keluaran sendiri"
                      tooltip={tooltip}
                      labelSX={{ fontSize: '0.875rem' }}
                    />
                  )}
                />
              </Grid>
            )
          }
          <Grid item xs={6}>
            <NumberTextField
              required
              fullWidth
              shrinkLabel
              name="quantity"
              label="Kuantiti"
              defaultValue={quantity}
              onChange={setQuantity}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              name="unit"
              label="Unit"
              defaultValue={oldData?.unit ?? ''}
              inputRef={unitTxtField}
            />
          </Grid>
          <Grid item xs={6} sx={{ alignItems: 'flex-end', height: '100%' }}>
            <NumberTextField
              required
              fullWidth
              shrinkLabel
              name="price"
              label="Harga Seunit (RM)"
              defaultValue={unitPrice}
              onChange={setUnitPrice}
            />
          </Grid>
          <Grid item xs={6}>
            <TotalCostBox>
              <Typography variant="h5" color="common.white">{formatCurrency(total)}</Typography>
              <Typography variant="caption" color="common.white">Jumlah Harga Kos</Typography>
            </TotalCostBox>
          </Grid>
        </Grid>
      </form>
    </CommonDialog>
  );
};

VariableCostDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showOwnProductOption: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  oldData: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    quantity: PropTypes.number,
    unit: PropTypes.string,
    isOwnProduct: PropTypes.bool
  })
};

VariableCostDialog.defaultProps = {
  showOwnProductOption: false,
  subtitle: undefined,
  oldData: undefined
};

export default VariableCostDialog;
