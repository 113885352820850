import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Divider, Drawer, List, Stack, Toolbar } from '@mui/material';

import ProfileListItem from './ProfileListItem';
import ProductListItem from './ProductLIstItem';
import SignOutListItem from './SignOutListItem';

const AppDrawer = ({ onClose, initialIndex, ...props }) => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex);

  const onSelect = (index) => {
    setSelectedIndex(index);
    onClose();
  };

  return (
    <Drawer
      {...props}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      sx={{
        flexShrink: 0,
        width: { xs: 240, sm: 300 },
        '& .MuiDrawer-paper': {
          width: { xs: 240, sm: 300 },
          boxSizing: 'border-box'
        }
      }}
      onClose={onClose}
    >
      <Toolbar />
      <List>
        <ProfileListItem selected={selectedIndex === 0} onClick={onSelect} />
        <ProductListItem selected={selectedIndex === 1} onClick={onSelect} />
        <Divider />
        <SignOutListItem />
      </List>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          px: 1,
          position: 'absolute',
          bottom: 10,
          cursor: 'pointer',
          color: 'primary.main'
        }}
        onClick={() => navigate('/')}
      >
        <ArrowBack sx={{ mr: 2 }} />
        <span>Kembali ke laman utama</span>
      </Stack>
    </Drawer>
  );
};

AppDrawer.propTypes = {
  ...Drawer.propTypes
};

AppDrawer.defaultProps = {
  ...Drawer.defaultProps
};

export default AppDrawer;
