import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';

import { Slide } from 'components/transitions';

const CommonDialog = ({
  title,
  subtitle,
  cancelLabel,
  saveLabel,
  formId,
  contentSX,
  onClose,
  onSave,
  children,
  ...props
}) => (
  <Dialog {...props} TransitionComponent={Slide} onClose={onClose}>
    <DialogTitle>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {title}
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
    </DialogTitle>
    <DialogContent sx={contentSX}>
      {children}
    </DialogContent>
    <DialogActions sx={{ justifyContent: 'flex-end' }}>
      <Button color="error" onClick={onClose}>
        <strong>{cancelLabel}</strong>
      </Button>
      <Button type="submit" form={formId} onClick={onSave}>
        <strong>{saveLabel}</strong>
      </Button>
    </DialogActions>
  </Dialog>
);

CommonDialog.propTypes = {
  ...Dialog.propTypes,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  formId: PropTypes.string,
  contentSX: Dialog.propTypes.sx,
  onSave: PropTypes.func
};

CommonDialog.defaultProps = {
  saveLabel: 'Simpan',
  cancelLabel: 'Batal',
  subtitle: undefined,
  formId: undefined,
  contentSX: undefined,
  onSave: undefined
};

export default CommonDialog;
