import { Dispatcher } from 'flux';

import Payload from './Payload';

class AppDispatcher extends Dispatcher {
  __tokens = new Map();
  suspend = false;
  isKeyboardTransform = false;

  constructor(name) {
    super();
    this.name = name;
  }

  setToken(storeName, token) {
    this.__tokens.set(storeName, token);
  }

  getToken(storeName) {
    const token = this.__tokens.get(storeName);
    if (!token) {
      if (process.env.NODE_ENV !== 'test') {
        throw new Error(`AppDispatcher: the token for ${storeName} is not stored`);
      } else {
        return '';
      }
    }
    return token;
  }
}

const dispatcher = new AppDispatcher('AppDispatcher');
export default dispatcher;

/**
 * Util function to dispatch action using AppDispatcher
 * @param  actionType    The action type that trigger the dispatch.
 * @param  [data=null]   The payload's data to be processed by dispatcher's registered callback
 * @param  [force=false] If `true`, force dispatching the action eventhough the dispatcher
 *                       is being suspended
 */
export function dispatchAction(actionType, data = null, force = false) {
  if (!dispatcher.suspend || force) {
    dispatcher.dispatch(new Payload(actionType, data));
  }
}

/** Get the AppDispatcher options
 * @param  name  The name of the option
 * @return       The option value
 */
export function getOption(name) {
  return dispatcher[name];
}

/**
 * A helper function to update the AppDispatcher options
 * @param option The option to be updated
 */
export function updateOptions(option) {
  Object.entries(option).forEach(([key, value]) => {
    dispatcher[key] = value;
  });
}
