import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';

import { EmptyList } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';
import ProductStore from 'stux/stores/ProductStore';
import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import DetailTabs from './DetailTabs';
import ProductInfo from './product-info/ProductInfo';
import ProductSummary from './summary/ProductSummary';
import CostStatement from './cost-statement/CostStatement';
import SalePrice from './sale-price/SalePrice';
import BreakEven from './break-even/BreakEven';
import { ProductDetailProvider } from './ProductDetailContext';
import { Container, Navigation } from './styles';

const ProductDetailPage = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [tabIndex, setTabIndex] = React.useState(0);
  const tabContents = React.useRef([ProductSummary, CostStatement, SalePrice, BreakEven]);
  const tabContent = React.useRef(ProductSummary);
  const productInfo = React.useRef(null);
  const { productName, statements, currentStatement, hasDeletedProduct } = useStux(
    ProductStore,
    'productName',
    'statements',
    'currentStatement',
    'hasDeletedProduct'
  );

  const onChangeTab = (index) => {
    tabContent.current = tabContents.current[index];
    setTabIndex(index);
  };

  React.useEffect(() => {
    ProductAction.initDB(UserStore.state.userId);
    ProductAction.getProductDetails(productId);
  }, []);

  React.useEffect(() => {
    if (hasDeletedProduct) {
      navigate('/products', { replace: true });
    }
  }, [hasDeletedProduct]);

  if (!productName || !statements) return null;

  return (
    <Container>
      <Navigation>
        <IconButton onClick={() => navigate('/products')}>
          <BackIcon />
        </IconButton>
        <Typography>Senarai Produk</Typography>
      </Navigation>
      <ProductInfo
        ref={productInfo}
        name={productName}
        statement={currentStatement?.statementDate}
      />
      {
        statements.length ? (
          <>
            <DetailTabs value={tabIndex} onChange={onChangeTab} />
            <ProductDetailProvider>
              <tabContent.current />
            </ProductDetailProvider>
          </>
        ) : (
          <Paper
            sx={{
              pb: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <EmptyList
              noLink
              message1="Maaf, tiada data untuk dipaparkan."
              sx={{ flex: 'unset !important' }}
            />
            <Box sx={{ mb: 3, width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="subtitle2"
                sx={{ mt: -3, mb: 1, width: '100%', textAlign: 'center' }}
              >
                Klik butang di bawah untuk mula
              </Typography>
              <Button
                variant="contained"
                sx={{ ml: 'auto', mr: 'auto' }}
                onClick={() => {
                  productInfo.current.showAddStatementDialog();
                }}
              >
                Tambah Penyata
              </Button>
            </Box>
          </Paper>
        )
      }
    </Container>
  );
};

export default ProductDetailPage;
