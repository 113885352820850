import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography } from '@mui/material';

import ListHeader from './ListHeader';

const GridItemCard = ({ header, title, children }) => (
  <Grid item xs={12}>
    <Paper sx={{ p: 2 }}>
      {!!header && <ListHeader title={header} />}
      {
        !!title && (
          <Typography color="primary" fontWeight="bold" sx={{ mb: 1 }}>
            {title}
          </Typography>
        )
      }
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Paper>
  </Grid>
);

GridItemCard.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node.isRequired
};

GridItemCard.defaultProps = {
  title: '',
  header: ''
};

export default GridItemCard;
