import * as React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';

const NumberTextField = ({
  readOnly,
  shrinkLabel,
  disableAutoZero,
  startAdornment,
  endAdornment,
  onBlur,
  onFocus,
  onChange,
  InputProps,
  InputLabelProps,
  ...props
}) => (
  <TextField
    {...props}
    type="number"
    readOnly={readOnly}
    InputLabelProps={{ shrink: shrinkLabel, ...InputLabelProps }}
    InputProps={{
      readOnly,
      startAdornment: !!startAdornment && (
        <InputAdornment disablePointerEvents position="start">{startAdornment}</InputAdornment>
      ),
      endAdornment: !!endAdornment && (
        <InputAdornment disablePointerEvents position="start">{endAdornment}</InputAdornment>
      ),
      ...InputProps
    }}
    onFocus={(e) => {
      if (!disableAutoZero && !readOnly && !Number(e.currentTarget.value)) {
        e.currentTarget.value = '';
      }
      if (onFocus) onFocus();
    }}
    onBlur={(e) => {
      if (!disableAutoZero && !readOnly && !Number(e.currentTarget.value)) {
        e.currentTarget.value = 0;
      }
      if (onBlur) onBlur();
    }}
    onChange={(e) => {
      if (onChange) {
        onChange(Number(e.currentTarget.value));
      }
    }}
  />
);

NumberTextField.propTypes = {
  ...TextField.propTypes,
  readOnly: PropTypes.bool,
  shrinkLabel: PropTypes.bool,
  disableAutoZero: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node
};

NumberTextField.defaultProps = {
  ...TextField.defaultProps,
  readOnly: false,
  shrinkLabel: false,
  disableAutoZero: false,
  startAdornment: undefined,
  endAdornment: undefined,
  InputProps: {},
  InputLabelProps: {}
};

export default NumberTextField;
