import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

export default function useAuthentication() {
  const isMounted = React.useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isIdentified, isAuthenticated } = useStux(UserStore, 'isIdentified', 'isAuthenticated');

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isMounted.current && isIdentified && isAuthenticated) {
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  }, [isIdentified, isAuthenticated]);

  return isAuthenticated;
}
