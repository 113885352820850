import * as React from 'react';
import PropTypes from 'prop-types';
import roundedToFixed from 'round-tofixed';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { NumberTextField } from 'components/textfield';

import { formatCurrency } from 'utils/formatter';

import { GridContainer, Result } from './styles';

const SimulationDialog = ({
  totalOrder,
  unitPrice,
  fixedCost,
  variableCost,
  onClose
}) => {
  const [calcTotalOrder, setCalcTotalOrder] = React.useState(totalOrder);
  const [calcUnitPrice, setCalcUnitPrice] = React.useState(unitPrice);

  const calcVariableCost = (variableCost / totalOrder) * calcTotalOrder;
  const totalCost = fixedCost + calcVariableCost;
  const totalSale = calcUnitPrice * calcTotalOrder;

  const totalProfitLost = totalSale - totalCost;
  const info = { label: 'Tiada Keuntungan atau Kerugian', themeColor: 'info' };
  if (totalProfitLost < 0) {
    info.label = 'Jumlah Kerugian';
    info.themeColor = 'error';
  } else if (totalProfitLost > 0) {
    info.label = 'Jumlah Keuntungan';
    info.themeColor = 'success';
  }

  return (
    <Dialog open fullWidth onClose={onClose}>
      <DialogTitle>Simulasi Jumlah Untung Rugi</DialogTitle>
      <DialogContent>
        <Stack spacing={1.5}>
          <Grid container spacing={2}>
            <Grid item xs>
              <NumberTextField
                fullWidth
                shrinkLabel
                label="Kuantiti Tempahan"
                endAdornment="unit"
                defaultValue={calcTotalOrder}
                onChange={setCalcTotalOrder}
              />
            </Grid>
            <Grid item xs>
              <NumberTextField
                readOnly
                fullWidth
                shrinkLabel
                label="Harga Jualan Seunit"
                startAdornment="RM"
                defaultValue={roundedToFixed(calcUnitPrice, 2)}
                onChange={setCalcUnitPrice}
              />
            </Grid>
          </Grid>
          <GridContainer>
            <Grid item xs>
              <TextField
                fullWidth
                readOnly
                label="Jumlah Kos Tetap"
                InputProps={{ readOnly: true }}
                value={formatCurrency(fixedCost)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                readOnly
                label="Jumlah Kos"
                InputProps={{ readOnly: true }}
                value={formatCurrency(totalCost || 0)}
              />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs>
              <TextField
                fullWidth
                readOnly
                label="Jumlah Kos Berubah"
                InputProps={{ readOnly: true }}
                value={formatCurrency(calcVariableCost || 0)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                readOnly
                label="Jumlah Jualan"
                InputProps={{ readOnly: true }}
                value={formatCurrency(totalSale)}
              />
            </Grid>
          </GridContainer>
          <GridContainer columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs />
            <Grid item xs={8} sm={6} md={6}>
              <Result $themeColor={info.themeColor}>
                <Typography variant="h5">{formatCurrency(Math.abs(totalProfitLost || 0))}</Typography>
                <Typography variant="subtitle2">
                  {info.label}
                </Typography>
              </Result>
            </Grid>
          </GridContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <strong>OK</strong>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SimulationDialog.propTypes = {
  unitPrice: PropTypes.number.isRequired,
  fixedCost: PropTypes.number.isRequired,
  variableCost: PropTypes.number.isRequired,
  totalOrder: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SimulationDialog;
