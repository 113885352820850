import * as React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Collapse, Stack, Typography } from '@mui/material';

import { ExpandButton } from 'components/buttons';

const CollapsibleCard = ({ title, isExpand, children, ...props }) => {
  const [expand, setExpand] = React.useState(isExpand);

  return (
    <Card {...props}>
      <CardContent sx={{ pb: '16px !important' }} onClick={() => setExpand((prev) => !prev)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight="bold" color="primary">
            {title}
          </Typography>
          <ExpandButton isExpand={expand} sx={{ p: 0 }} />
        </Stack>
      </CardContent>
      <Collapse unmountOnExit timeout="auto" in={expand}>
        <CardContent sx={{ pt: 0 }}>
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

CollapsibleCard.propTypes = {
  ...Card.propTypes,
  title: PropTypes.string,
  isExpand: PropTypes.bool
};

CollapsibleCard.defaultProps = {
  title: '',
  isExpand: false
};

export default CollapsibleCard;
