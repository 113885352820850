import * as React from 'react';
import PropTypes from 'prop-types';

import { CommonDialog } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';

const DeleteStatementDialog = ({ onClose }) => (
  <CommonDialog
    open
    title="Hapus Penyata Semasa"
    cancelLabel="Tidak"
    saveLabel="Ya"
    onClose={onClose}
    onSave={() => {
      ProductAction.deleteStatement();
      onClose();
    }}
  >
    Anda pasti untuk menghapuskan penyata semasa ini?
  </CommonDialog>
);

DeleteStatementDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DeleteStatementDialog;
