import * as React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material';

const DetailTabs = ({ value, onChange }) => (
  <Tabs
    scrollButtons
    allowScrollButtonsMobile
    variant="scrollable"
    sx={{ maxWidth: 'calc(100vw - 32px)' }}
    value={value}
    onChange={(_, newValue) => onChange(newValue)}
  >
    <Tab label="Ringkasan" />
    <Tab label="Penyata Kos" />
    <Tab label="Harga Jualan" />
    <Tab label="Titik Pulang Modal" />
  </Tabs>
);

DetailTabs.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DetailTabs;
