import * as React from 'react';
import { Card } from '@mui/material';

import { ReadOnlyToggleTextField } from 'components/textfield';

import ProfileAction from 'stux/actions/ProfileAction';
import ProfileStore from 'stux/stores/ProfileStore';
import { useStux } from 'stux/utils';

import ProfileCard from './ProfileCard';

const BusinessProfile = ({ ...props }) => {
  const [editable, setEditable] = React.useState(false);
  const [name, setName] = React.useState('');
  const [reg, setReg] = React.useState('');
  const [site, setSite] = React.useState('');
  const [tel, setTel] = React.useState('');
  useStux(ProfileStore, (state) => {
    setName(state.businessName ?? '');
    setReg(state.registrationNumber ?? '');
    setTel(state.businessPhoneNo ?? '');
    setSite(state.website ?? '');
  }, true, 'businessName', 'registrationNumber', 'businessPhoneNo', 'website');

  const onSave = () => {
    setEditable(false);

    const { businessName, registrationNumber, businessPhoneNo, website } = ProfileStore.state;

    if (
      name !== businessName || reg !== registrationNumber
      || site !== website || tel !== businessPhoneNo
    ) {
      ProfileAction.updateBusinessProfile(name, reg, tel, site);
    }
  };

  return (
    <ProfileCard
      {...props}
      editMode={editable}
      onSave={onSave}
      onToggleEdit={() => {
        setEditable((prev) => {
          const newState = !prev;
          if (!newState) {
            const {
              businessName,
              registrationNumber,
              businessPhoneNo,
              website
            } = ProfileStore.state;
            setName(businessName ?? '');
            setReg(registrationNumber ?? '');
            setTel(businessPhoneNo ?? '');
            setSite(website ?? '');
          }
          return newState;
        });
      }}
    >
      <ReadOnlyToggleTextField
        fullWidth
        label="Nama Perusahaan"
        readOnly={!editable}
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />
      <ReadOnlyToggleTextField
        fullWidth
        label="No Pendaftaran Syarikat"
        readOnly={!editable}
        value={reg}
        onChange={(e) => setReg(e.currentTarget.value)}
      />
      <ReadOnlyToggleTextField
        fullWidth
        label="No Telefon"
        readOnly={!editable}
        value={tel}
        onChange={(e) => setTel(e.currentTarget.value)}
      />
      <ReadOnlyToggleTextField
        fullWidth
        type="url"
        label="Website"
        readOnly={!editable}
        value={site}
        style={{ marginBottom: '16px' }}
        onChange={(e) => setSite(e.currentTarget.value)}
      />
    </ProfileCard>
  );
};

BusinessProfile.propTypes = {
  ...Card.propTypes
};

export default BusinessProfile;
