import styled from 'styled-components';
import { Grid } from '@mui/material';

export const ItemContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > :not(:first-child) {
    margin-top: ${({ theme, $shallowMargin }) => theme.spacing($shallowMargin ? 1 : 2)};
  }
`;
