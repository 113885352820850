import {
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  runTransaction
} from 'firebase/firestore';

import { getDB, initFirestore, resetFirestore } from 'firebaseUtil/db';

let __userId;

export function initDB(userId) {
  initFirestore();
  __userId = userId;
}

export function resetDB() {
  resetFirestore();
  __userId = undefined;
}

export async function getProducts() {
  const db = getDB();
  const q = query(collection(db, 'product'), where('userId', 'array-contains', __userId));
  const querySnapshot = await getDocs(q);
  const productList = [];
  querySnapshot.forEach((docData) => {
    // docData.data() is never undefined for query doc snapshots
    const data = docData.data();
    productList.push({
      productId: docData.id,
      productName: data.productName,
      statements: data.statements
    });
  });
  return productList;
}

export async function addProduct(productName) {
  try {
    const db = getDB();
    const basicData = {
      productName,
      statements: []
    };

    const ref = await addDoc(collection(db, 'product'), {
      ...basicData,
      userId: [__userId, 'superuser']
    });
    return {
      productList: [{
        ...basicData,
        productId: ref.id
      }]
    };
  } catch (err) {
    console.error('error add product:', err);
    return {
      productListError: 10001
    };
  }
}

export async function changeProductName(productId, productName) {
  try {
    const db = getDB();
    const ref = doc(db, 'product', productId);
    await updateDoc(ref, { productName });
    return { productName };
  } catch (err) {
    console.error('error change product name:', err);
    return {
      productDetailError: 20001
    };
  }
}

export async function getStatement(statementId) {
  try {
    const db = getDB();
    const docSnap = await getDoc(doc(db, 'statement', statementId));
    if (docSnap.exists()) {
      return { ...docSnap.data() };
    }
    throw new Error('Data not exist');
  } catch (err) {
    console.error('error get statement:', err);
    return {
      productDetailError: 20003
    };
  }
}

export async function getProdutDetail(products, productId) {
  try {
    let productList = products;
    const newProductList = {};
    if (!products) {
      productList = await getProducts();
      newProductList.productList = productList;
    }

    const product = productList.find((item) => item.productId === productId);
    let data;
    if (product) {
      const statements = product.statements.slice().sort((item1, item2) => item2.date - item1.date);
      data = {
        productId,
        statements,
        productName: product.productName,
        productDetailError: 0
      };
    } else {
      data = {
        productId,
        productName: undefined,
        statements: undefined,
        productDetailError: 20000
      };
    }

    let currentStatement;
    if (data.statements?.length) {
      const statementId = data.statements[0].id;
      currentStatement = await getStatement(statementId);
      currentStatement.statementId = statementId;
    }

    return {
      ...newProductList,
      ...data,
      currentStatement
    };
  } catch (err) {
    console.error('error getProdutDetail:', err);
    return {
      currentStatement: undefined,
      productDetailError: 20004
    };
  }
}

export async function addStatement(productId, statements, statementDate) {
  try {
    const db = getDB();
    const newStatement = {
      statementDate,
      userId: [__userId, 'superuser'],
      selfMaterialCosts: [],
      foreignMaterialCosts: [],
      labourCosts: [],
      productionOverheadCosts: [],
      nonProductionCosts: [],
      otherFixedCosts: [],
      otherVariableCosts: [],
      totalOrder: 0,
      markup: 0,
      discount: 0,
      tax: 0,
      delivery: 0
    };
    const statementRef = await addDoc(collection(db, 'statement'), newStatement);

    const productRef = doc(db, 'product', productId);
    const newStatementRef = { id: statementRef.id, date: statementDate };
    await updateDoc(productRef, { statements: arrayUnion(newStatementRef) });
    return {
      statements: [...statements, newStatementRef],
      currentStatement: {
        ...newStatement,
        statementId: statementRef.id
      }
    };
  } catch (err) {
    console.error('error add statement:', err);
    return {
      productDetailError: 20002
    };
  }
}

export async function addCost(statementId, costType, costData) {
  try {
    const db = getDB();
    const ref = doc(db, 'statement', statementId);
    await updateDoc(ref, { [costType]: arrayUnion(costData) });
    return {
      productDetailError: 0
    };
  } catch (err) {
    console.error('error add cost:', err);
    return {
      productDetailError: 20005
    };
  }
}

export async function updateCost(statementId, costType, costId, costData) {
  try {
    const db = getDB();
    const ref = doc(db, 'statement', statementId);
    const newData = await runTransaction(db, async (transaction) => {
      const docSnap = await transaction.get(ref);
      if (!docSnap.exists()) throw new Error('Document does not exist!');

      const docData = docSnap.data()[costType];
      let newDocData;

      if (!costData) {
        // if no data is supplied, that means the item must be deleted
        newDocData = docData.filter((val) => val.id !== costId);
      } else {
        const itemIndex = docData.findIndex((val) => val.id === costId);
        if (itemIndex < 0) throw new Error('Document item does not exist!');

        docData[itemIndex] = costData;
        newDocData = docData;
      }

      transaction.update(ref, { [costType]: newDocData });
      return newDocData;
    });

    return { newData };
  } catch (err) {
    console.error('error update cost:', err);
    return {
      productDetailError: 20008
    };
  }
}

export async function updateSale(statementId, saleData) {
  try {
    const db = getDB();
    const ref = doc(db, 'statement', statementId);
    await updateDoc(ref, saleData);
    return {
      productDetailError: 0
    };
  } catch (err) {
    console.error('error update sale data:', err);
    return {
      productDetailError: 20006
    };
  }
}

export async function deleteStatement(productId, statementId, statementData, nextStatementId) {
  try {
    const db = getDB();
    const statementRef = doc(db, 'statement', statementId);
    await deleteDoc(statementRef);

    const productRef = doc(db, 'product', productId);
    await updateDoc(productRef, {
      statements: arrayRemove(statementData)
    });

    let nextStatement;
    if (nextStatementId) {
      nextStatement = await getStatement(nextStatementId);
      nextStatement.statementId = nextStatementId;
    }
    return {
      currentStatement: nextStatement,
      productDetailError: 0
    };
  } catch (err) {
    console.error('error delete statement:', err);
    return {
      productDetailError: 20007
    };
  }
}

export async function deleteProduct(productId, statements) {
  try {
    const db = getDB();
    const promises = statements.map((statement) => {
      const statementRef = doc(db, 'statement', statement.id);
      return deleteDoc(statementRef);
    });

    await Promise.all(promises);
    await deleteDoc(doc(db, 'product', productId));

    return {};
  } catch (err) {
    console.error('error delete product:', err);
    return {
      productDetailError: 20009
    };
  }
}
