import * as React from 'react';
import PropTypes from 'prop-types';
import roundToFixed from 'round-tofixed';

import ProductStore from 'stux/stores/ProductStore';
import { useStux } from 'stux/utils';

const defaultData = {
  currentStatement: undefined,
  materialCost: 0,
  labourCost: 0,
  overheadCost: 0,
  nonProductionCost: 0,
  otherVariableCost: 0,
  otherFixedCost: 0,
  variableCost: 0,
  fixedCost: 0,
  unitCost: 0,
  unitPrice: 0,
  totalCost: 0,
  totalSale: 0
};

const ProductDetailContext = React.createContext({ ...defaultData });

const reduceCost = (acc, cur) => (
  acc + (cur.value * (cur.quantity || 1))
);

export const calculateProductDetail = (currentStatement) => {
  const {
    foreignMaterialCosts,
    labourCosts,
    productionOverheadCosts,
    nonProductionCosts,
    otherVariableCosts,
    otherFixedCosts,
    markup,
    totalOrder
  } = currentStatement;

  const materialCost = foreignMaterialCosts.reduce(reduceCost, 0);
  const labourCost = labourCosts.reduce(reduceCost, 0);
  const overheadCost = productionOverheadCosts.reduce(reduceCost, 0);
  const nonProductionCost = nonProductionCosts.reduce(reduceCost, 0);
  const otherVariableCost = otherVariableCosts.reduce(reduceCost, 0);
  const otherFixedCost = otherFixedCosts.reduce(reduceCost, 0);
  const variableCost = materialCost + labourCost + otherVariableCost;
  const fixedCost = overheadCost + nonProductionCost + otherFixedCost;

  const totalCost = variableCost + fixedCost;
  const unitCost = totalOrder ? totalCost / totalOrder : 0;

  const unitPrice = roundToFixed(((markup * 0.01) * unitCost) + unitCost, 2);
  const totalSale = totalOrder * unitPrice;

  return {
    materialCost,
    labourCost,
    overheadCost,
    nonProductionCost,
    otherFixedCost,
    otherVariableCost,
    variableCost,
    fixedCost,
    unitCost,
    unitPrice,
    totalCost,
    totalSale
  };
};

export const useProductDetail = () => React.useContext(ProductDetailContext);

export const ProductDetailProvider = ({ children }) => {
  const { currentStatement } = useStux(ProductStore, 'currentStatement');

  const data = React.useMemo(() => {
    if (currentStatement) {
      const calculatedData = calculateProductDetail(currentStatement);
      return { currentStatement, ...calculatedData };
    }

    return { ...defaultData };
  }, [currentStatement]);

  return (
    <ProductDetailContext.Provider value={data}>
      {children}
    </ProductDetailContext.Provider>
  );
};

ProductDetailProvider.propTypes = {
  children: PropTypes.node
};

ProductDetailProvider.defaultProps = {
  children: null
};
