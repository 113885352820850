import { initializeApp, getApps } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const devConfig = {
  apiKey: 'AIzaSyAn4zJPPluoW8SKj-SN3px5Rc1fCzfTXuU',
  authDomain: 'dev-hitung-untung.firebaseapp.com',
  projectId: 'dev-hitung-untung',
  storageBucket: 'dev-hitung-untung.appspot.com',
  messagingSenderId: '54733528867',
  appId: '1:54733528867:web:08dbb8f0033b67b8563696',
  measurementId: 'G-K1QQWZQFQL'
};

const stageConfig = {
  apiKey: 'AIzaSyAKTgL4d8tV6FfqIsOJaaDNoNdT87m9mHU',
  authDomain: 'staging-hitung-untung.firebaseapp.com',
  projectId: 'staging-hitung-untung',
  storageBucket: 'staging-hitung-untung.appspot.com',
  messagingSenderId: '45635495663',
  appId: '1:45635495663:web:26f8e274be168d8d0a1d1a',
  measurementId: 'G-HMF1YKC32C'
};

const prodConfig = {
  apiKey: 'AIzaSyAzZ3Hr5Sg7BE45dwju0WRvbbsS8_UQUqw',
  authDomain: 'hitung-untung.firebaseapp.com',
  projectId: 'hitung-untung',
  storageBucket: 'hitung-untung.appspot.com',
  messagingSenderId: '191363569129',
  appId: '1:191363569129:web:8ebf72b2a03e0ea8ab6778',
  measurementId: 'G-14VYD27Z8Y'
};

let __app;

export function initFirebaseApp() {
  if (!getApps().length) {
    let config;
    const env = process.env.REACT_APP_ENV;
    switch (env) {
      case 'production':
        config = prodConfig;
        break;
      case 'staging':
        config = stageConfig;
        break;
      default:
        config = devConfig;
        break;
    }
    __app = initializeApp(config);

    if (env !== 'development') {
      getAnalytics(__app);
    }
  }
}

export function getApp() {
  if (!__app) {
    initFirebaseApp();
  }

  return __app;
}
