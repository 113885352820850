import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Paper, Typography } from '@mui/material';

import { InfoLabel } from 'components/typography';

import { formatCurrency } from 'utils/formatter';

const CostSummaryCard = ({ title, tooltip, variableCost, fixedCost, variant }) => (
  <Grid item xs={12}>
    <Paper
      sx={{
        p: 2,
        background: ({ palette: { primary } }) => (
          variant === 'dark' ? `linear-gradient(to right, ${primary.dark}, ${primary.main})` : 'white'
        )
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs sx={{ color: variant === 'dark' ? 'alternative.main' : 'inherit' }}>
          {
            tooltip ? (
              <InfoLabel
                label={title}
                tooltip={tooltip}
                sx={{ mb: 0.5 }}
                labelProps={{
                  color: variant === 'dark' ? 'inherit' : 'primary.light',
                  fontWeight: 'bold'
                }}
              />
            ) : (
              <Typography
                fontWeight="bold"
                color={variant === 'dark' ? 'inherit' : 'primary.light'}
                sx={{ pb: 0.5 }}
              >
                {title}
              </Typography>
            )
          }
          <Typography variant="h4">
            {formatCurrency(variableCost + fixedCost)}
          </Typography>
        </Grid>
        <Grid item xs sx={{ color: variant === 'dark' ? 'white' : 'inherit' }}>
          <Box sx={{ mb: Number.isFinite(fixedCost) ? 1.5 : 0 }}>
            <Typography variant="subtitle2">Jumlah Kos Berubah</Typography>
            <Typography>{formatCurrency(variableCost)}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Jumlah Kos Tetap</Typography>
            <Typography>{formatCurrency(fixedCost)}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

CostSummaryCard.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark']),
  variableCost: PropTypes.number.isRequired,
  fixedCost: PropTypes.number.isRequired
};

CostSummaryCard.defaultProps = {
  title: 'Jumlah Kos',
  tooltip: undefined,
  variant: 'light'
};

export default CostSummaryCard;
