import * as React from 'react';
import PropTypes from 'prop-types';
import roundedToFixed from 'round-tofixed';
import { Grid } from '@mui/material';

import { GridItemCard, GridItemContent, ValueLabel } from 'pages/commons';

import { LongLabelNumberTextfield } from './styles';

const TotalSale = ({ discount, unitPrice, totalSale, onChange }) => (
  <GridItemCard title="Kiraan Jumlah Jualan">
    <GridItemContent xs={12}>
      <Grid container spacing={2}>
        <GridItemContent>
          <LongLabelNumberTextfield
            fullWidth
            shrinkLabel
            label="Diskaun"
            endAdornment="%"
            defaultValue={discount}
            onChange={onChange}
          />
        </GridItemContent>
        <GridItemContent>
          <LongLabelNumberTextfield
            readOnly
            fullWidth
            shrinkLabel
            label="Harga Jualan Seunit Selepas Diskaun"
            startAdornment="RM"
            value={roundedToFixed(unitPrice - (unitPrice * (discount * 0.01)), 2)}
          />
        </GridItemContent>
      </Grid>
    </GridItemContent>
    <GridItemContent shallowMargin xs={12}>
      <ValueLabel label="Jumlah Jualan Kasar (Sebelum Diskaun)" value={totalSale} />
      <ValueLabel
        label="Jumlah Jualan Bersih (Selepas Diskaun)"
        value={totalSale - (totalSale * (discount * 0.01))}
      />
    </GridItemContent>
  </GridItemCard>
);

TotalSale.propTypes = {
  discount: PropTypes.number.isRequired,
  unitPrice: PropTypes.number.isRequired,
  totalSale: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TotalSale;
