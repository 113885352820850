import * as React from 'react';
import PropTypes from 'prop-types';
import roundedToFixed from 'round-tofixed';

import { NumberTextField } from 'components/textfield';

import { GridItemCard, GridItemContent, ValueLabel } from 'pages/commons';

const CostPerUnit = ({ totalCost, totalOrder, costPerUnit, errorText, onChange }) => (
  <GridItemCard title="Kiraan Kos Seunit">
    <GridItemContent>
      <NumberTextField
        fullWidth
        shrinkLabel
        label="Kuantiti Tempahan"
        endAdornment="unit"
        error={!!errorText}
        defaultValue={totalOrder}
        helperText={errorText}
        onChange={onChange}
      />
    </GridItemContent>
    <GridItemContent>
      <NumberTextField
        readOnly
        fullWidth
        shrinkLabel
        label="Jumlah Kos"
        startAdornment="RM"
        value={roundedToFixed(totalCost, 2).toFixed(2)}
      />
      <ValueLabel label="Kos Seunit" value={costPerUnit} />
    </GridItemContent>
  </GridItemCard>
);

CostPerUnit.propTypes = {
  totalCost: PropTypes.number.isRequired,
  totalOrder: PropTypes.number.isRequired,
  costPerUnit: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string
};

CostPerUnit.defaultProps = {
  errorText: ''
};

export default CostPerUnit;
