import styled from 'styled-components';
import { Alert, Avatar, Container as MUIContainer, Paper } from '@mui/material';

export const Container = styled(MUIContainer)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Content = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const Logo = styled(Avatar)`
  width: ${({ $hasLogo }) => ($hasLogo ? 167.6785 : 80)}px;
  height: ${({ $hasLogo }) => ($hasLogo ? 150 : 80)}px;
  background-color: ${({ theme, $hasLogo }) => (!$hasLogo ? theme.palette.primary.light : 'unset')};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :not(:first-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & > :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ItemContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  & > :not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Status = styled(Alert)`
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
`;
