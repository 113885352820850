import styled from 'styled-components';
import { Chip, Paper, Typography } from '@mui/material';

export const Card = styled(Paper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & > * {
      text-align: ${({ $shouldCenter }) => ($shouldCenter ? 'center' : 'unset')};
    }
  }
`;

export const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;

export const DiscountChip = styled(Chip)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.error.light};
`;

export const DiscountedValue = styled(Typography)`
  text-decoration: line-through;
  font-size: ${({ theme, $isBig }) => (
    $isBig ? theme.typography.subtitle1.fontSize : theme.typography.subtitle2.fontSize
  )};
`;
