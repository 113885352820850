import * as React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Tab, Tabs, Typography } from '@mui/material';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import { colorFromString, getReadablColor } from 'utils/colors';

import { ProfileBannerContainer } from './styles';

const ProfileBanner = ({ tabIndex, onChangeTab }) => {
  const { username, displayName } = useStux(UserStore, 'displayName');

  const name = displayName ?? username.split('@')[0];
  const bgcolor = colorFromString(name);
  const color = getReadablColor(bgcolor);

  return (
    <ProfileBannerContainer>
      <Avatar sx={{ border: '3px solid white', bgcolor, color, width: 80, height: 80 }}>
        <Typography variant="h3">
          {name[0].toLocaleUpperCase()}
        </Typography>
      </Avatar>
      <Typography sx={{ mt: 1, textTransform: 'capitalize' }}>{name}</Typography>
      <Tabs
        variant="fullWidth"
        textColor="inherit"
        sx={{ width: '100%', mt: 4, mb: -1 }}
        TabIndicatorProps={{ sx: { bgcolor: 'common.white' } }}
        value={tabIndex}
        onChange={(_, newValue) => onChangeTab(newValue)}
      >
        <Tab label="Butiran Peribadi" />
        <Tab label="Butiran Syarikat" />
      </Tabs>
    </ProfileBannerContainer>
  );
};

ProfileBanner.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  onChangeTab: PropTypes.func.isRequired
};

export default ProfileBanner;
