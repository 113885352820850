import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { formatCurrency } from 'utils/formatter';

import { DiscountContainer, DiscountChip } from './styles';

const DiscountLabel = ({ value, discount, size }) => (
  !!discount && (
    <DiscountContainer>
      <Typography variant={size === 'small' ? 'subtitle2' : 'subtitle1'}>
        <s>{formatCurrency(value)}</s>
      </Typography>
      <DiscountChip size={size} label={`Diskaun ${discount}%`} />
    </DiscountContainer>
  )
);

DiscountLabel.propTypes = {
  value: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['medium', 'small'])
};

DiscountLabel.defaultProps = {
  size: 'medium'
};

export default DiscountLabel;
