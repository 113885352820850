import * as React from 'react';
import PropTypes from 'prop-types';
import roundToFixed from 'round-tofixed';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';

import { InfoLabel } from 'components/typography';

import { GridItemCard, GridItemContent, ValueLabel } from 'pages/commons';

import SimulationDialog from './SimulationDialog';

const tooltip = 'Titik Pulang Modal\n\nSatu tahap kuantiti pengeluaran dan harga jualan tanpa untung atau rugi';

const ComputedBreakEven = ({ unitPrice, fixedCost, variableCost, totalSale, totalOrder }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const totalProfitMargin = totalSale - variableCost;
  const marginPerUnit = totalProfitMargin / totalOrder;
  const minUnit = marginPerUnit ? fixedCost / marginPerUnit : 0;

  return (
    <>
      <GridItemCard>
        <GridItemContent>
          <Stack spacing={1.5}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <InfoLabel
                label="Titik Pulang Modal"
                tooltip={tooltip}
                labelProps={{ color: 'primary', fontWeight: 'bold' }}
              />
              <Button variant="contained" onClick={() => setOpenDialog(true)}>Simulasi</Button>
            </Stack>
            <Divider />
            <ValueLabel
              labelVariant="subtitle2"
              labelColor="common.black"
              label="Harga Jualan Seunit"
              value={unitPrice}
            />
            <Grid container>
              <GridItemContent xs={12} shallowMargin>
                {/* <ValueLabel
                  label="Minimum Harga Jualan Seunit"
                  value={totalOrder ? (minUnit * unitPrice) / totalOrder : 0}
                /> */}
                <Box>
                  <Typography color="error.light">Titik Pulang Modal (Unit)</Typography>
                  <Typography variant="h4">{roundToFixed(minUnit, 4)}</Typography>
                </Box>
                <Box>
                  <Typography color="error.light">Titik Pulang Modal (Unit dibundarkan)</Typography>
                  <Typography variant="h4">{Math.ceil(minUnit)}</Typography>
                </Box>
                <ValueLabel label="Titik Pulang Modal (Jualan)" value={minUnit * unitPrice} />
              </GridItemContent>
            </Grid>
          </Stack>
        </GridItemContent>
      </GridItemCard>
      {
        openDialog && (
          <SimulationDialog
            unitPrice={unitPrice}
            totalOrder={totalOrder}
            fixedCost={fixedCost}
            variableCost={variableCost}
            onClose={() => setOpenDialog(false)}
          />
        )
      }
    </>
  );
};

ComputedBreakEven.propTypes = {
  unitPrice: PropTypes.number.isRequired,
  fixedCost: PropTypes.number.isRequired,
  variableCost: PropTypes.number.isRequired,
  totalSale: PropTypes.number.isRequired,
  totalOrder: PropTypes.number.isRequired
};

export default ComputedBreakEven;
