import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { ItemContent } from './gridItemStyles';

const GridItemContent = ({ children, xs, shallowMargin, ...props }) => (
  <ItemContent {...props} item xs={xs} $shallowMargin={shallowMargin}>
    {children}
  </ItemContent>
);

GridItemContent.propTypes = {
  ...Grid.propTypes,
  shallowMargin: PropTypes.bool,
  children: PropTypes.node.isRequired
};

GridItemContent.defaultProps = {
  ...Grid.defaultProps,
  shallowMargin: false,
  item: true,
  xs: true
};

export default GridItemContent;
