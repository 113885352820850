import { getFirestore } from 'firebase/firestore';

import { getApp } from './app';

let __db;

export function initFirestore() {
  if (!__db) {
    const app = getApp();
    __db = getFirestore(app);
  }
}

export function getDB() {
  if (!__db) {
    initFirestore();
  }
  return __db;
}

export function resetFirestore() {
  __db = undefined;
}
