import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight as MoreIcon } from '@mui/icons-material';
import { Box, Button, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';

import ProductAction from 'stux/actions/ProductAction';
import ProductStore from 'stux/stores/ProductStore';
import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import { EmptyList, ListHeader } from 'pages/commons';

import AddProductButton from './AddProductButton';
import { Content, ListItem } from './styles';

const ProductListPage = () => {
  const navigate = useNavigate();
  const addBtn = React.useRef(null);
  const { productList } = useStux(ProductStore, 'productList');

  React.useState(() => {
    ProductAction.initDB(UserStore.state.userId);
    ProductAction.getProducts();
  }, []);

  if (!productList) {
    return null;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <ListHeader title="Senarai Produk">
        <AddProductButton ref={addBtn} />
      </ListHeader>
      <Content>
        <List>
          {
            productList.length ? (
              productList.map(({ productId, productName, statements }) => (
                <React.Fragment key={productName}>
                  <ListItem
                    divider
                    disablePadding
                    secondaryAction={<MoreIcon color="primary" />}
                    onClick={() => {
                      navigate(`/products/${productId}`);
                    }}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={productName}
                        secondary={`${statements.length} penyata`}
                      />
                    </ListItemButton>
                  </ListItem>
                </React.Fragment>
              ))
            ) : (
              <>
                <EmptyList
                  noLink
                  message1="Tiada senarai produk."
                />
                <Box sx={{ mb: 3, width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: -3, mb: 1, width: '100%', textAlign: 'center' }}
                  >
                    Klik butang di bawah untuk mula
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ ml: 'auto', mr: 'auto' }}
                    onClick={() => {
                      addBtn.current.showDialog();
                    }}
                  >
                    Tambah Produk
                  </Button>
                </Box>
              </>
            )
          }
        </List>
      </Content>
    </Paper>
  );
};

export default ProductListPage;
