import styled from 'styled-components';
import {
  ListItem as MUILIstItem
} from '@mui/material';

export const ListItem = styled(MUILIstItem)`
  justify-content: space-between;

  & .MuiListItemSecondaryAction-root {
    right: ${({ theme }) => theme.spacing(0.5)};
    position: relative;
    transform: unset;
    flex-shrink: 0;
  }
`;

export const ListItemContent = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 0, 1.5)};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const TotalCostBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1.5)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

export const MenuItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;
