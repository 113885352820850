import styled from 'styled-components';
import { IconButton as MUIIconButton } from '@mui/material';

export const IconButton = styled(MUIIconButton)`
  transform: ${({ $expand }) => (!$expand ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: ${({ theme }) => (
    theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  )}
`;
