import styled from 'styled-components';
import { Box, Grid } from '@mui/material';

export const GridContainer = styled(Grid).attrs({
  container: true,
  spacing: 2
})`
  margin-left: ${({ theme }) => theme.spacing(-2)} !important;
`;

export const Result = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2, 1.5)};
  border-radius: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme, $themeColor }) => theme.palette[$themeColor].main};
`;
