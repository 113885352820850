import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';

import { InfoLabel } from 'components/typography';

import { formatCurrency } from 'utils/formatter';

import DiscountLabel from './DiscountLabel';
import { Card } from './styles';

const LargeItemCard = ({ title, tooltip, value, variableCost, fixedCost, discount }) => {
  const realValue = value - (value * (discount / 100));

  return (
    <Grid item xs={12}>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs>
            <InfoLabel
              label={title}
              tooltip={tooltip}
              sx={{ mb: 0.5 }}
              labelProps={{ color: 'primary.light', fontWeight: 'bold' }}
            />
            <Typography variant="h4">
              {formatCurrency(realValue)}
            </Typography>
          </Grid>
          <Grid item xs>
            {
              !!discount && (
                <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'start', height: '100%' }}>
                  <DiscountLabel value={value} discount={discount} />
                </Box>
              )
            }
            {
              !!variableCost && (
                <Box sx={{ mb: fixedCost ? 2 : 0 }}>
                  <Typography variant="subtitle2">Jumlah Kos Berubah</Typography>
                  <Typography>{formatCurrency(variableCost)}</Typography>
                </Box>
              )
            }
            {
              !!fixedCost && (
                <Box>
                  <Typography variant="subtitle2">Jumlah Kos Tetap</Typography>
                  <Typography>{formatCurrency(fixedCost)}</Typography>
                </Box>
              )
            }
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

LargeItemCard.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  variableCost: PropTypes.number,
  fixedCost: PropTypes.number,
  discount: PropTypes.number
};

LargeItemCard.defaultProps = {
  tooltip: '',
  variableCost: 0,
  fixedCost: 0,
  discount: 0
};

export default LargeItemCard;
