import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { resetPassword } from 'stux/data-managers/authentication';

import { Status } from './styles';

const PasswordResetDialog = ({ onClose }) => {
  const [status, setStatus] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(false);
  const txtField = React.useRef(null);
  const onReset = async (e) => {
    const email = txtField.current?.value;
    if (email) {
      e.preventDefault();
      setIsDisabled(true);

      const resetStatus = await resetPassword(email);
      setStatus(resetStatus);
    }
  };

  return (
    <Dialog open fullWidth disableEscapeKeyDown>
      <DialogTitle>
        Permohonan Tukar Katalaluan
      </DialogTitle>
      <DialogContent>
        <p>
          Sila masukkan emel anda. Satu pautan akan dihantar ke emel tersebut.
          Klik pada pautan yang diberikan untuk menukar katalaluan.
        </p>
        <form id="emailForm">
          <TextField
            required
            fullWidth
            type="email"
            variant="outlined"
            label="Emel"
            inputRef={txtField}
          />
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: !status ? 'flex-end' : 'center' }}>
        {
          !status ? (
            <>
              <Button color="error" onClick={onClose}><strong>Batal</strong></Button>
              <Button type="submit" form="emailForm" disabled={isDisabled} onClick={onReset}>
                <strong>Set Semula Katalaluan</strong>
              </Button>
            </>
          ) : (
            <Status
              severity={status}
              action={<Button color="inherit" size="small" onClick={onClose}>OK</Button>}
            >
              {
                status === 'success'
                  ? 'The email had been sent. Check you email'
                  : 'There is an error. Make sure the email is correct'
              }
            </Status>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

PasswordResetDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PasswordResetDialog;
