import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';

import LogoImg from 'logo/logo956.png';

import ProductAction from 'stux/actions/ProductAction';
import ProfileAction from 'stux/actions/ProfileAction';
import ProductStore from 'stux/stores/ProductStore';
import ProfileStore from 'stux/stores/ProfileStore';
import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

import AppDrawer from './drawer/AppDrawer';
import ProcessingOverlay from './ProcessingOverlay';
import { RootDiv, Content } from './styles';

const MainPage = () => {
  const location = useLocation();
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [drawerIndex, setDrawerIndex] = React.useState(undefined);
  const { isAuthenticated } = useStux(UserStore, 'isAuthenticated');

  React.useEffect(() => {
    ProductStore.init();
    ProfileStore.init();
  }, []);

  React.useEffect(() => {
    if (isAuthenticated) {
      const { pathname } = location;
      let index;
      if (pathname.includes('/products')) index = 1;
      else if (pathname.includes('/profile')) index = 0;
      setDrawerIndex(index);
    } else {
      setDrawerIndex(undefined);
      ProfileAction.reset();
      ProductAction.reset();
    }
  }, [isAuthenticated, location]);

  const hasDrawer = drawerIndex !== undefined;

  return (
    <RootDiv>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Toolbar sx={{ pr: 0 }}>
          {
            !matches && hasDrawer && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => setOpenDrawer((prev) => !prev)}
              >
                <MenuIcon />
              </IconButton>
            )
          }
          <Typography
            variant="h6"
            component="div"
            sx={{
              display: 'flex',
              justifyContent: matches ? 'center' : 'space-between',
              alignItems: 'center',
              flexDirection: matches ? 'row' : 'row-reverse',
              flexGrow: 1,
              textAlign: { xs: 'left', sm: 'center' }
            }}
          >
            <img
              alt="Hitung Untung"
              src={LogoImg}
              style={{
                width: matches ? '2.5rem' : '1.85rem',
                marginRight: matches ? '1rem' : '0.5rem'
              }}
            />
            <span>Hitung Untung</span>
          </Typography>
        </Toolbar>
      </AppBar>
      {
        hasDrawer && (
          <AppDrawer
            initialIndex={drawerIndex}
            open={openDrawer}
            variant={matches ? 'permanent' : 'temporary'}
            onClose={() => setOpenDrawer(matches)}
          />
        )
      }
      <Content>
        <Toolbar />
        {/* need to put the ProcessingOverlay before the Outlet so that ProcessingOverlay
            will ll be mounted first to let it listen to the Profile store changes first
         */}
        {isAuthenticated && <ProcessingOverlay />}
        <Grid
          container
          justifyContent="center"
          sx={{ p: hasDrawer ? 2 : 0 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
        >
          <Grid item xs={4} sm={hasDrawer ? 6 : 8} md={hasDrawer ? 8 : 12} lg={hasDrawer ? 6 : 12}>
            <Outlet />
          </Grid>
        </Grid>
      </Content>
    </RootDiv>
  );
};

export default MainPage;
