import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing(-1.5)};

  & > button:hover {
    background-color: unset;
  }
`;
