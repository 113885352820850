import roundToFixed from 'round-tofixed';

import { formatCurrency } from 'utils/formatter';

const costHeaderStyle = 'text-align: center; background-color: #dedede; '
                        + 'border-right: 1px solid lightgrey; border-bottom: 1px solid lightgrey';
const head = `
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
    <style>
      body {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        background-color: transparent;
      }

      table {
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
        max-width: stretch;
        font-size: 14px;
        page-break-inside: auto;
      }

      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }

      td {
        text-transform: capitalize;
        border-top: 1px solid lightgrey;
        padding: 4px;
      }

      td:first-child {
        border-left: 1px solid lightgrey;
      }

      tr:last-child td {
        border-bottom: 1px solid lightgrey;
      }

      tr:nth-child(odd) {
        background-color: #f2f2f2;
      }

      tr td:not(first-child) {
        border-right: none;
      }

      tr td:last-child {
        border-right: 1px solid lightgrey;
      }

      .report-info-data {
        font-size: 16px;
        padding-left: 0;
        background-color: white;
        border: none !important;
      }
    </style>
  </head>
`;

function getReportInfoRow(label, value) {
  return `
    <tr>
      <td class="report-info-data" style="width: 28%">${label}</td>
      <td class="report-info-data" style="width: 4%; text-align: center">:</td>
      <td class="report-info-data" style="width: 68%">${value}</td>
    </tr>
  `;
}

function getReportInfo(businessName, registrationNumber, productName, statementDate) {
  return `
    <table>
      <tbody>
        ${businessName ? getReportInfoRow('Nama Perusahaan', businessName) : ''}
        ${registrationNumber ? getReportInfoRow('No Pendaftaran', registrationNumber) : ''}
        ${getReportInfoRow('Nama Produk', productName)}
        ${getReportInfoRow('Tarikh Penyata', statementDate)}
      </tbody>
    </table>
  `;
}

function getSummaryRow(label, value) {
  return `
    <tr>
      <td style="width: 40%">${label}</td>
      <td style="width: 4%; text-align: center">:</td>
      <td style="width: 56%">
        ${value}
      </td>
    </tr>
  `;
}

function getCostItemStyle(width, textAlign = 'left', noRightBorder = false) {
  const borderRight = noRightBorder ? '' : 'border-right: 1px solid lightgrey';
  return `style="width: ${width}%; text-align: ${textAlign}; border-top: none; ${borderRight}"`;
}

function getTableTitle(title, colspan) {
  return `
    <tr style="background-color: transparent">
      <td colspan="${colspan}" style="padding-left: 0; border: 0">
        <h4 style="margin-bottom: 2px"><strong>${title}</strong></h4>
      </td>
    </tr>
  `;
}

function getVariableCost(label, total, costs) {
  const costItems = costs.map(({ name, quantity, value }, index) => (
    `<tr>
      <td ${getCostItemStyle(5, 'center')}>${index + 1}</td>
      <td ${getCostItemStyle(45)}>${name}</td>
      <td ${getCostItemStyle(10, 'center')}>${quantity}</td>
      <td ${getCostItemStyle(20, 'right')}>${formatCurrency(value)}</td>
      <td ${getCostItemStyle(20, 'right', true)}>${formatCurrency(value * quantity)}</td>
    </tr>`
  ));

  return `
    <table style="width: 100%">
      <tbody>
        ${getTableTitle(label, 5)}
        <tr>
          <td style="width: 5%; ${costHeaderStyle}">No</td>
          <td style="width: 45%; ${costHeaderStyle}">Item</td>
          <td style="width: 10%; ${costHeaderStyle}">Kuantiti</td>
          <td style="width: 20%; ${costHeaderStyle}">Harga Seunit</td>
          <td style="width: 20%; ${costHeaderStyle}">Jumlah</td>
        </tr>
        ${costItems.join('\n')}
        <tr style="background-color: transparent">
          <td colspan="3" style="width: 60%; text-align: center; border-top: 1px solid lightgrey">
            <br>
          </td>
          <td style="width: 20%; text-align: right"><strong>JUMLAH</strong></td>
          <td style="width: 20%; text-align: right">
            <strong>${formatCurrency(total)}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  `;
}

function getFixedCost(label, total, costs) {
  const costItems = costs.map(({ name, value }, index) => (
    `<tr>
      <td ${getCostItemStyle(5, 'center')}>${index + 1}</td>
      <td ${getCostItemStyle(75)}>${name}</td>
      <td ${getCostItemStyle(20, 'right', true)}>${formatCurrency(value)}</td>
    </tr>`
  ));

  return `
    <table style="width: 100%">
      <tbody>
        ${getTableTitle(label, 3)}
        <tr>
          <td style="width: 5%; ${costHeaderStyle}">No</td>
          <td style="width: 75%; ${costHeaderStyle}">Item</td>
          <td style="width: 20%; ${costHeaderStyle}">Jumlah</td>
        </tr>
        ${costItems.join('\n')}
        <tr style="background-color: transparent">
          <td style="width: 5%; text-align: center; border-top: 1px solid lightgrey">
            <br>
          </td>
          <td style="width: 75%; text-align: right"><strong>JUMLAH</strong></td>
          <td style="width: 20%; text-align: right">
            <strong>${formatCurrency(total)}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  `;
}

export function getPrintHtml(
  productName,
  statementDate,
  {
    foreignMaterialCosts = [],
    labourCosts = [],
    productionOverheadCosts = [],
    nonProductionCosts = [],
    otherVariableCosts = [],
    otherFixedCosts = [],
    markup = 0,
    discount = 0,
    tax = 0,
    delivery = 0,
    totalOrder = 0
  },
  {
    materialCost,
    labourCost,
    overheadCost,
    nonProductionCost,
    otherFixedCost,
    otherVariableCost,
    variableCost,
    fixedCost,
    unitCost,
    unitPrice,
    totalCost,
    totalSale
  },
  {
    businessName,
    registrationNumber
  }
) {
  const variableCostPerUnit = totalOrder ? variableCost / totalOrder : 0;
  const fixedCostPerUnit = totalOrder ? fixedCost / totalOrder : 0;

  const totalProfitMargin = totalSale - variableCost;
  const grossMarginPct = totalSale ? ((totalSale - variableCost) / totalSale) * 100 : 0;
  const profitMarginPerUnit = totalOrder ? totalProfitMargin / totalOrder : 0;

  const totalSaleAfterDiscount = totalSale - (totalSale * (discount * 0.01));

  const sale = totalSale + (totalSale * (tax * 0.01));
  const netSale = sale + delivery;
  const netSaleAfterDiscount = (sale - (sale * discount * 0.01)) + delivery;

  const totalProfitLoss = totalSale - totalCost;
  const netMarginPct = totalSale ? ((totalSale - totalCost) / totalSale) * 100 : 0;

  const minUnit = profitMarginPerUnit ? fixedCost / profitMarginPerUnit : 0;
  const minSale = minUnit * unitPrice;

  return `
    <html>
      ${head}
      <body>
        ${getReportInfo(businessName, registrationNumber, productName, statementDate)}

        <h3>
          <strong>Ringkasan</strong>
        </h3>
        <table style="width: 100%">
          <tbody>
            ${getSummaryRow('Unit Tempahan', totalOrder)}
            ${getSummaryRow('Jumlah Kos Berubah', formatCurrency(variableCost))}
            ${getSummaryRow('Jumlah Kos Tetap', formatCurrency(fixedCost))}
            ${getSummaryRow('Jumlah Kos', formatCurrency(variableCost + fixedCost))}
            ${getSummaryRow('Kos Berubah Seunit', formatCurrency(variableCostPerUnit))}
            ${getSummaryRow('Kos Tetap Seunit', formatCurrency(fixedCostPerUnit))}
            ${getSummaryRow('Kos Seunit', formatCurrency(unitCost))}
            ${getSummaryRow('Mark Up', `${markup}%`)}
            ${getSummaryRow('Harga Jualan Seunit', formatCurrency(unitPrice))}
            ${getSummaryRow('Margin Keuntungan Seunit', formatCurrency(profitMarginPerUnit))}
            ${getSummaryRow('Peratus Margin Kasar Atas Jualan', `${roundToFixed(grossMarginPct, 2)}%`)}
            ${getSummaryRow('Jumlah Margin Keuntungan', formatCurrency(totalProfitMargin))}
            ${getSummaryRow('Diskaun', `${discount}%`)}
            ${getSummaryRow('GST / SST', `${tax}%`)}
            ${getSummaryRow('Kos Penghantaran', formatCurrency(delivery))}
            ${getSummaryRow('Jumlah Jualan Sebelum Diskaun', formatCurrency(totalSale))}
            ${getSummaryRow('Jumlah Jualan Selepas Diskaun', formatCurrency(totalSaleAfterDiscount))}
            ${getSummaryRow('Hasil Jualan Sebelum Diskaun', formatCurrency(netSale))}
            ${getSummaryRow('Hasil Jualan Selepas Diskaun', formatCurrency(netSaleAfterDiscount))}
            ${getSummaryRow('Jumlah Untung/Rugi', formatCurrency(totalProfitLoss))}
            ${getSummaryRow('Peratus Margin Bersih Atas Jualan', `${roundToFixed(netMarginPct, 2)}%`)}
            ${getSummaryRow('Minimum Unit Perlu Dijual', Math.ceil(minUnit))}
            ${getSummaryRow('Nilai Jualan Minimum', formatCurrency(minSale))}
          </tbody>
        </table>

        <div style="page-break-after: always"></div>
        
        <h3 style="margin-top: 0; margin-bottom: 2px">
          <strong>Penyata Kos</strong>
        </h3>
        ${foreignMaterialCosts.length ? getVariableCost('Kos Bahan Langsung (Kos Berubah)', materialCost, foreignMaterialCosts) : ''}
        ${labourCosts.length ? getVariableCost('Kos Buruh Langsung (Kos Berubah)', labourCost, labourCosts) : ''}
        ${otherVariableCosts.length ? getVariableCost('Lain-lain Kos Berubah', otherVariableCost, otherVariableCosts) : ''}
        ${productionOverheadCosts.length ? getFixedCost('Kos Overhed Pengeluaran (Kos Tetap)', overheadCost, productionOverheadCosts) : ''}
        ${nonProductionCosts.length ? getFixedCost('Kos Overhed Operasi (Kos Tetap)', nonProductionCost, nonProductionCosts) : ''}
        ${otherFixedCosts.length ? getFixedCost('Lain-lain Kos Tetap', otherFixedCost, otherFixedCosts) : ''}
      </body>
    </html>
  `;
}
