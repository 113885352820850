import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { BuildWithFirebaseLogo, MUILogo, ReactLogo, ReactRouterLogo } from './logo';

const HitungUntung = () => (
  <Box
    sx={{
      p: 3,
      display: 'flex',
      width: '100%',
      color: 'white',
      justifyContent: 'center',
      backgroundColor: 'primary.dark'
    }}
  >
    <Stack
      spacing={3}
      alignItems="center"
      sx={{ maxWidth: '1280px' }}
    >
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h5" fontWeight="bold">Hitung Untung</Typography>
        <Typography variant="subtitle1">
          <p>
            The web-based application where you can store the list of your business products
            in our secure database. You can add information of the raw materials used in the
            products, record the production costs and retrieve a summary of product costings
            <i>
              &nbsp;(e.g: break even points, minimum sales needed,
              profit margins and much more)&nbsp;
            </i>
            while you are on the go.
          </p>
          <p>
            This Gold award winner of the International Invention, Innovation &amp; Technology
            Exhibition (ITEX) Competition 2022 is very easy and convenient to use. You don&apos;t
            even need to install anything on your device! Just register with us
            and you are good to go.
          </p>
          <p>
            With Hitung Untung, your business accounting is delivered at your fingertips.
          </p>
        </Typography>
      </Box>
      <Box sx={{ mb: '-1rem !important' }}>
        <BuildWithFirebaseLogo width="15rem" />
      </Box>
      <Typography variant="h6" fontWeight="bold" textAlign="center" sx={{ mb: '-0.75rem !important' }}>
        This is also made possible by these awesome libs
      </Typography>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        sx={{
          '& > *': {
            mt: 0,
            mx: 2,
            mb: 4
          }
        }}
      >
        <Box><ReactLogo /></Box>
        <Stack alignItems="center">
          <MUILogo width="7rem" height="7rem" />
          <Typography variant="h6" color="#00B0FF" sx={{ mt: -1 }}>Material-UI</Typography>
        </Stack>
        <Stack alignItems="center">
          <ReactRouterLogo width="8.8rem" height="6.5rem" />
          <Typography variant="h6" color="#D0021B">React Router</Typography>
        </Stack>
      </Stack>
    </Stack>
  </Box>
);

export default HitungUntung;
