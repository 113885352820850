import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { GroupWork as ProductIcon } from '@mui/icons-material';
import { Avatar, ListItemButton, ListItemAvatar, ListItemText } from '@mui/material';

const ProductListItem = ({ onClick, ...props }) => {
  const navigate = useNavigate();

  return (
    <ListItemButton
      {...props}
      onClick={() => {
        onClick(1);
        navigate('/products');
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'primary.light' }}>
          <ProductIcon fontSize="large" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Produk" />
    </ListItemButton>
  );
};

ProductListItem.propTypes = {
  ...ListItemButton.propTypes
};

ProductListItem.defaultProps = {
  ...ListItemButton.defaultProps
};

export default ProductListItem;
