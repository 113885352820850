import styled from 'styled-components';
import { ListItem as MUIListItem } from '@mui/material';

export const Content = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const ListItem = styled(MUIListItem)`
  & .MuiListItemSecondaryAction-root {
    display: flex;
    align-items: center;
    pointer-events: none;
    margin-right: ${({ theme }) => theme.spacing(-1)};
  }
`;
