import * as React from 'react';
import PropTypes from 'prop-types';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { IconButton as MUIIconButton } from '@mui/material';

import { IconButton } from './expandButtonStyle';

const ExpandButton = ({ isExpand, ...props }) => (
  <IconButton {...props} $expand={isExpand}>
    <ExpandMoreIcon />
  </IconButton>
);

ExpandButton.propTypes = {
  ...MUIIconButton.propTypes,
  isExpand: PropTypes.bool
};

ExpandButton.defaultProps = {
  isExpand: false
};

export default ExpandButton;
