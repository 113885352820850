import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

import { formatCurrency } from 'utils/formatter';

import { Card, DiscountedValue } from './styles';

const DetailItemCard = ({
  unitPrice,
  discount,
  totalSale,
  fixedCost,
  variableCost,
  totalOrder
}) => {
  const hasDiscount = discount !== 0;
  const discountDecimal = discount * 0.01;
  const totalCost = fixedCost + variableCost;
  const netProfit = totalSale - totalCost;
  const realTotalSale = totalSale - (totalSale * discountDecimal);
  const realNetProfit = realTotalSale - variableCost - fixedCost;

  const grossProfit = totalSale - variableCost;
  const realGrossProfit = realTotalSale - variableCost;

  const marginPerUnit = totalOrder ? grossProfit / totalOrder : 0;
  const realMarginPerUnit = totalOrder ? (realTotalSale - variableCost) / totalOrder : 0;

  const realUnitPrice = unitPrice - (unitPrice * discountDecimal);
  const grossMarginPercentage = unitPrice ? (marginPerUnit / unitPrice) * 100 : 0;
  const realGrossMarginPercentage = realUnitPrice ? (realMarginPerUnit / realUnitPrice) * 100 : 0;

  const netMarginPercentage = totalSale ? (netProfit / totalSale) * 100 : 0;
  const realNetMarginPercentage = realTotalSale ? (realNetProfit / realTotalSale) * 100 : 0;

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          color: 'common.white',
          background: (theme) => (
            `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
          )
        }}
      >
        <Typography fontWeight="bold" color="alternative.main">Jumlah Untung Bersih</Typography>
        <Stack direction="row" spacing={2} alignItems="end">
          <Typography variant="h4" color="alternative.main">
            {formatCurrency(realNetProfit)}
          </Typography>
          {hasDiscount && <DiscountedValue $isBig>{formatCurrency(netProfit)}</DiscountedValue>}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="end" sx={{ mt: 2 }}>
          <Typography variant="h5">{formatCurrency(realGrossProfit)}</Typography>
          {hasDiscount && <DiscountedValue $isBig>{formatCurrency(grossProfit)}</DiscountedValue>}
        </Stack>
        <Typography>Jumlah Untung Kasar</Typography>
        <Divider variant="thick" sx={{ borderColor: 'common.white', mt: 2, mb: 2 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
            <Typography variant="subtitle2" sx={{ width: '55%' }}>Margin Kasar Keuntungan Seunit</Typography>
            <Typography variant="subtitle2">{formatCurrency(realMarginPerUnit)}</Typography>
            {hasDiscount && <DiscountedValue>{formatCurrency(marginPerUnit)}</DiscountedValue>}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
            <Typography variant="subtitle2" sx={{ width: '55%' }}>Peratus Margin Kasar atas Jualan</Typography>
            <Typography variant="subtitle2">{`${Math.round(realGrossMarginPercentage)}%`}</Typography>
            {hasDiscount && <DiscountedValue>{`${Math.round(grossMarginPercentage)}%`}</DiscountedValue>}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
            <Typography variant="subtitle2" sx={{ width: '55%' }}>Peratus Margin Bersih atas Jualan</Typography>
            <Typography variant="subtitle2">{`${Math.round(realNetMarginPercentage)}%`}</Typography>
            {hasDiscount && <DiscountedValue>{`${Math.round(netMarginPercentage)}%`}</DiscountedValue>}
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

DetailItemCard.propTypes = {
  unitPrice: PropTypes.number.isRequired,
  totalSale: PropTypes.number.isRequired,
  fixedCost: PropTypes.number.isRequired,
  variableCost: PropTypes.number.isRequired,
  totalOrder: PropTypes.number.isRequired,
  discount: PropTypes.number
};

DetailItemCard.defaultProps = {
  discount: 0
};

export default DetailItemCard;
