import * as React from 'react';
import { Grid } from '@mui/material';

import Summary from './Summary';
import ComputedBreakEven from './ComputedBreakEven';
import { useProductDetail } from '../ProductDetailContext';

const BreakEven = () => {
  const productDetail = useProductDetail();

  return (
    <Grid container spacing={2}>
      <Summary productDetail={productDetail} />
      <ComputedBreakEven
        fixedCost={productDetail.fixedCost}
        variableCost={productDetail.variableCost}
        unitPrice={productDetail.unitPrice}
        totalSale={productDetail.totalSale}
        totalOrder={productDetail.currentStatement?.totalOrder ?? 0}
      />
    </Grid>
  );
};

export default BreakEven;
