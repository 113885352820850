import * as React from 'react';
import PropTypes from 'prop-types';
import msLocale from 'date-fns/locale/ms';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/lab';

import { dateFromString } from 'utils/formatter';

import { CommonDialog } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';
import ProductStore from 'stux/stores/ProductStore';

const AddStatementDialog = ({ onClose }) => {
  const statementDates = React.useRef(ProductStore.state.statements.map(({ date }) => date));
  const [value, setValue] = React.useState(new Date());

  const onSave = () => {
    const date = format(value, 'yyyy-MM-dd');
    const dateTime = dateFromString(date).getTime();
    ProductAction.addStatement(dateTime);
    onClose();
  };

  return (
    <CommonDialog
      open
      title="Tambah Penyata"
      contentSX={{ p: 0 }}
      onClose={onClose}
      onSave={onSave}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={msLocale}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={value}
          renderInput={(params) => <TextField {...params} />}
          shouldDisableDate={(date) => {
            const d = dateFromString(format(date, 'yyyy-MM-dd'));
            return statementDates.current.includes(d.getTime());
          }}
          onChange={setValue}
        />
      </LocalizationProvider>
    </CommonDialog>
  );
};

AddStatementDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AddStatementDialog;
