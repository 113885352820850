import * as React from 'react';
import PropTypes from 'prop-types';
import roundedToFixed from 'round-tofixed';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { CollapsibleCard } from 'components/containers';

import { GridItemContent, ValueLabel } from 'pages/commons';

import SummaryContent from './SummaryContent';

const tips = {
  sale: 'Jualan\n\nHasil yang diperolehi daripada aktiviti jualan produk atau pekhidmatan.\n\n'
        + 'Pengiraan:\nHarga jualan seunit produk atau perkhidmatan (setelah diskaun) x '
        + 'bilangan unit yang dijual',
  variableCost: 'Kos Berubah\n\nHarga bahan mentah atau sebarang aktiviti yang berkaitan '
                + 'meningkat mengikut peningkatan unit produk yang dihasilkan. Ianya '
                + 'terbahagi kepada kos bahan langsung, kos buruh langsung dan lain-lain bahan langsung',
  fixedCost: 'Kos Tetap\n\nBayaran yang perlu dibuat untuk operasi perniagaan dan tiada '
             + 'kaitan samada produk dihasilkan atau tidak tetapi tetap perlu dibayar.'
};

const Summary = ({ productDetail }) => {
  const {
    materialCost,
    labourCost,
    otherVariableCost,
    overheadCost,
    nonProductionCost,
    otherFixedCost,
    variableCost,
    fixedCost,
    unitPrice,
    totalSale,
    currentStatement: {
      totalOrder = 0
    }
  } = productDetail;

  const totalCost = variableCost + fixedCost;

  const totalProfitMargin = totalSale - variableCost;
  const grossMargin = totalSale ? ((totalSale - variableCost) / totalSale) * 100 : 0;
  const marginPerUnit = totalOrder ? totalProfitMargin / totalOrder : 0;
  const netMargin = totalSale ? ((totalSale - totalCost) / totalSale) * 100 : 0;

  return (
    <Grid item xs={12}>
      <CollapsibleCard title="Ringkasan">
        <Stack spacing={1.5}>
          <SummaryContent title="Jualan" tooltip={tips.sale}>
            <Grid container>
              <GridItemContent>
                <ValueLabel
                  labelVariant="subtitle2"
                  labelColor="common.black"
                  label="Harga Jualan Seunit"
                  value={unitPrice}
                />
              </GridItemContent>
              <GridItemContent>
                <Box>
                  <Typography variant="subtitle2">Kuantiti Jualan (unit)</Typography>
                  <Typography variant="h4">{totalOrder}</Typography>
                </Box>
              </GridItemContent>
            </Grid>
            <ValueLabel label="Jumlah Jualan" value={totalSale} />
          </SummaryContent>
          <SummaryContent title="Kos Berubah" tooltip={tips.variableCost}>
            <ValueLabel
              labelVariant="subtitle2"
              labelColor="common.black"
              label="Kos Bahan Langsung Seunit"
              value={totalOrder ? materialCost / totalOrder : 0}
            />
            <ValueLabel
              labelVariant="subtitle2"
              labelColor="common.black"
              label="Kos Buruh Langsung Seunit"
              value={totalOrder ? labourCost / totalOrder : 0}
            />
            <ValueLabel
              labelVariant="subtitle2"
              labelColor="common.black"
              label="Lain-lain Kos Berubah Seunit"
              value={totalOrder ? otherVariableCost / totalOrder : 0}
            />
            <Grid container>
              <GridItemContent>
                <ValueLabel label="Kos Berubah Seunit" value={totalOrder ? variableCost / totalOrder : 0} />
              </GridItemContent>
              <GridItemContent>
                <ValueLabel label="Jumlah Kos Berubah" value={variableCost} />
              </GridItemContent>
            </Grid>
          </SummaryContent>
          <SummaryContent title="Margin Keuntungan" tooltip={tips.variableCost}>
            <Grid container>
              <GridItemContent>
                <ValueLabel
                  label="Margin Keuntungan Seunit"
                  labelVariant="subtitle2"
                  labelColor="common.black"
                  value={marginPerUnit}
                />
              </GridItemContent>
              <GridItemContent>
                <Box>
                  <Typography variant="subtitle2">Peratus Margin Kasar atas Jualan</Typography>
                  <Typography variant="h4">{`${roundedToFixed(grossMargin, 2)}%`}</Typography>
                </Box>
              </GridItemContent>
            </Grid>
            <ValueLabel label="Jumlan Margin Keuntungan" value={totalProfitMargin} />
          </SummaryContent>
          <SummaryContent title="Kos Tetap" tooltip={tips.fixedCost}>
            <Grid container>
              <GridItemContent>
                <ValueLabel
                  labelVariant="subtitle2"
                  labelColor="common.black"
                  label="Kos Overhed Pengeluaran"
                  value={overheadCost}
                />
              </GridItemContent>
              <GridItemContent>
                <ValueLabel
                  labelVariant="subtitle2"
                  labelColor="common.black"
                  label="Kos Overhed Operasi"
                  value={nonProductionCost}
                />
              </GridItemContent>
            </Grid>
            <Grid container>
              <GridItemContent>
                <ValueLabel
                  labelVariant="subtitle2"
                  labelColor="common.black"
                  label="Lain-lain Kos Tetap"
                  value={otherFixedCost}
                />
              </GridItemContent>
              <GridItemContent>
                <ValueLabel label="Jumlan Kos Tetap" value={fixedCost} />
              </GridItemContent>
            </Grid>
          </SummaryContent>
          <SummaryContent title="Keuntungan Bersih Atas Jualan" tooltip={tips.variableCost}>
            <Grid container>
              <GridItemContent>
                <ValueLabel
                  labelVariant="subtitle2"
                  labelColor="common.black"
                  label="Jumlah Untung/Rugi"
                  value={totalSale - totalCost}
                />
              </GridItemContent>
              <GridItemContent>
                <Box>
                  <Typography variant="subtitle2">Peratus Margin Bersih atas Jualan</Typography>
                  <Typography variant="h4">{`${roundedToFixed(netMargin, 2)}%`}</Typography>
                </Box>
              </GridItemContent>
            </Grid>
          </SummaryContent>
        </Stack>
      </CollapsibleCard>
    </Grid>
  );
};

Summary.propTypes = {
  productDetail: PropTypes.shape({
    materialCost: PropTypes.number,
    labourCost: PropTypes.number,
    otherVariableCost: PropTypes.number,
    overheadCost: PropTypes.number,
    nonProductionCost: PropTypes.number,
    otherFixedCost: PropTypes.number,
    variableCost: PropTypes.number,
    fixedCost: PropTypes.number,
    unitCost: PropTypes.number,
    unitPrice: PropTypes.number,
    totalSale: PropTypes.number,
    currentStatement: PropTypes.shape({
      totalOrder: PropTypes.number
    })
  }).isRequired
};

export default Summary;
