import * as React from 'react';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import UserStore from 'stux/stores/UserStore';
import { useStux } from 'stux/utils';

const btnSx = {
  px: 3,
  py: 1.5,
  minWidth: 250,
  fontSize: '1.15rem',
  fontWeight: 'bold',
  textTransform: 'none'
};

const primaryBtnSx = {
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)'
  }
};

const Shortcut = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useStux(UserStore, 'isAuthenticated');

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
    >
      {
        isAuthenticated && (
          <Button
            color="inherit"
            sx={{ ...btnSx, ...primaryBtnSx }}
            onClick={() => navigate('/products')}
          >
            Let&apos;s get started
          </Button>
        )
      }
      {
        !isAuthenticated && (
          <>
            <Button
              color="inherit"
              sx={{ ...btnSx, ...primaryBtnSx }}
              onClick={() => navigate('/login')}
            >
              Login to your account
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              sx={{ ...btnSx }}
              onClick={() => navigate('/sign-up')}
            >
              Register now
            </Button>
          </>
        )
      }
    </Stack>
  );
};

export default Shortcut;
