import * as React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Typography } from '@mui/material';

const IconMenuItem = ({ label, labelVariant, noDivider, icon: Icon, onClick }) => (
  <MenuItem divider={!noDivider} onClick={onClick}>
    <Icon />
    <Typography variant={labelVariant}>{label}</Typography>
  </MenuItem>
);

IconMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  labelVariant: Typography.propTypes.variant,
  noDivider: PropTypes.bool
};

IconMenuItem.defaultProps = {
  labelVariant: 'subtitle2',
  noDivider: false
};

export default IconMenuItem;
