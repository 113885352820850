import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import { CommonDialog } from 'pages/commons';

import ProductAction from 'stux/actions/ProductAction';

const ChangeProductNameDialog = ({ oldName, onClose }) => {
  const txtField = React.useRef(null);

  const onSave = (e) => {
    const productName = txtField.current?.value;
    const isSimilar = productName === oldName;
    if (productName && !isSimilar) {
      e.preventDefault();
      ProductAction.changeProductName(productName);
      onClose();
    } else if (isSimilar) {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <CommonDialog
      open
      fullWidth
      title="Pinda Nama Produk"
      formId="productForm"
      onClose={onClose}
      onSave={onSave}
    >
      <form id="productForm">
        <TextField
          required
          fullWidth
          label="Nama Produk"
          inputRef={txtField}
          defaultValue={oldName}
        />
      </form>
    </CommonDialog>
  );
};

ChangeProductNameDialog.propTypes = {
  oldName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ChangeProductNameDialog;
