import * as React from 'react';
import PropTypes from 'prop-types';
import { Chip, IconButton, ListItemText, Menu, Stack, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreIcon
} from '@mui/icons-material';

import { IconMenuItem } from 'components/buttons';

import { formatCurrency } from 'utils/formatter';

import { ListItem, ListItemContent } from './styles';

const CostListItem = ({ id, name, value, quantity, isOwnProduct, unit, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onClickEdit = () => {
    onEdit({ id, name, value, quantity, isOwnProduct, unit });
    onCloseMenu();
  };

  const onClickDelete = () => {
    onDelete(id);
    onCloseMenu();
  };

  return (
    <>
      <ListItem
        divider
        disablePadding
        key={id}
        secondaryAction={(
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography color="error.light">{formatCurrency(value * quantity)}</Typography>
            <IconButton sx={{ p: 0, mr: '-4px !important' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
              <MoreIcon />
            </IconButton>
          </Stack>
        )}
      >
        <ListItemContent>
          <ListItemText sx={{ textTransform: 'capitalize' }} primary={name} />
          <div>
            {
              isOwnProduct && (
                <Typography
                  color="alternative.dark"
                  variant="caption"
                  sx={{ display: 'block', mt: -0.75, mb: 0.5 }}
                >
                  Keluaran Sendiri
                </Typography>
              )
            }
            {
              !!(quantity && unit) && (
                <Chip
                  size="small"
                  variant="outlined"
                  sx={{ borderColor: 'primary.light', mt: -0.5 }}
                  label={`${formatCurrency(value)} x ${quantity} ${unit}`}
                />
              )
            }
          </div>
        </ListItemContent>
      </ListItem>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onCloseMenu}>
        <IconMenuItem label="Ubah" icon={EditIcon} onClick={onClickEdit} />
        <IconMenuItem noDivider label="Hapus" icon={DeleteIcon} onClick={onClickDelete} />
      </Menu>
    </>
  );
};

CostListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  isOwnProduct: PropTypes.bool,
  quantity: PropTypes.number,
  unit: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

CostListItem.defaultProps = {
  isOwnProduct: false,
  quantity: 1,
  unit: undefined,
  onEdit: () => undefined,
  onDelete: () => undefined
};

export default CostListItem;
