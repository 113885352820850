import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

import { formatCurrency } from 'utils/formatter';

const ValueLabel = ({ label, labelVariant, labelColor, value }) => (
  <Box>
    <Typography variant={labelVariant} color={labelColor}>
      {label}
    </Typography>
    <Typography variant="h4">
      {formatCurrency(value)}
    </Typography>
  </Box>
);

ValueLabel.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  labelVariant: PropTypes.string,
  labelColor: PropTypes.string
};

ValueLabel.defaultProps = {
  labelVariant: undefined,
  labelColor: 'error.light'
};

export default ValueLabel;
