import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountCircle as ProfileIcon } from '@mui/icons-material';
import { Avatar, ListItemButton, ListItemAvatar, ListItemText } from '@mui/material';

const ProfileListItem = ({ onClick, ...props }) => {
  const navigate = useNavigate();

  return (
    <ListItemButton
      {...props}
      onClick={() => {
        onClick(0);
        navigate('/profile');
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'secondary.light' }}>
          <ProfileIcon fontSize="large" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Profil" />
    </ListItemButton>
  );
};

ProfileListItem.propTypes = {
  ...ListItemButton.propTypes
};

ProfileListItem.defaultProps = {
  ...ListItemButton.defaultProps
};

export default ProfileListItem;
