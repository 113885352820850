import deepCopy from 'fast-copy';
import { generateUUID } from 'utils/uuid';

import ProductAction from 'stux/actions/ProductAction';

import StatefulStore from './StatefulStore';
import AppDispatcher from '../dispatchers/AppDispatcher';
import {
  initDB,
  resetDB,
  addProduct,
  getProducts,
  changeProductName,
  addStatement,
  getProdutDetail,
  addCost,
  updateCost,
  updateSale,
  getStatement,
  deleteStatement,
  deleteProduct
} from '../data-managers/products';

class ProductStore extends StatefulStore {
  constructor() {
    super({
      isProcessing: false,
      productList: undefined,
      productId: undefined,
      productName: undefined,
      statements: undefined,
      currentStatement: undefined,
      hasDeletedProduct: false,
      productListError: 0,
      productDetailError: 0
    }, false);
    // TODO: remove the below line and use decorator when CRA allows it
    this.constructor.prototype.storeName = 'ProductStore';
    this._subscribe(AppDispatcher);
  }

  _init() {
    this._registerAction(ProductAction.INIT_DB, this.__onInitDB);
    this._registerAction(ProductAction.RESET, this.__onReset);
    this._registerAction(ProductAction.GET_PRODUCTS, this.__onGetProducts);
    this._registerAction(ProductAction.ADD_PRODUCT, this.__onAddProduct);
    this._registerAction(ProductAction.GET_PRODUCT_DETAIL, this.__onGetDetail);
    this._registerAction(ProductAction.CHANGE_PRODUCT_NAME, this.__onChangeName);
    this._registerAction(ProductAction.ADD_STATEMENT, this.__onAddStatement);
    this._registerAction(ProductAction.ADD_COST, this.__onAddCost);
    this._registerAction(ProductAction.DELETE_COST, this.__onUpdateCost);
    this._registerAction(ProductAction.UPDATE_COST, this.__onUpdateCost);
    this._registerAction(ProductAction.UPDATE_SALE, this.__onUpdateSale);
    this._registerAction(ProductAction.SELECT_STATEMENT, this.__onSelectStatement);
    this._registerAction(ProductAction.DELETE_STATEMENT, this.__onDeleteStatement);
    this._registerAction(ProductAction.DELETE_PRODUCT, this.__onDeleteProduct);
  }

  __onInitDB = ({ userId }) => {
    initDB(userId);
  };

  __onReset = () => {
    resetDB();
    this._updateState({
      productList: undefined,
      productId: undefined,
      productName: undefined,
      statements: undefined,
      currentStatement: undefined,
      hasDeletedProduct: false
    });
  };

  __onGetProducts = () => {
    if (!this._state.productList) {
      getProducts()
        .then((data) => {
          this._updateState({
            productList: data
          });
        });
    }
  };

  __onAddProduct = ({ productName }) => {
    const exist = this._state.productList?.some((item) => item.productName === productName);
    if (!exist) {
      this._updateState({ isProcessing: true });
      addProduct(productName)
        .then((data) => {
          const { productList, productListError } = data;
          if (productList) {
            this._updateState({
              isProcessing: false,
              productList: [...productList, ...(this._state.productList ?? [])]
            });
          } else {
            this._updateState({ productListError });
          }
        });
    } else {
      this._updateState({
        isProcessing: false,
        productListError: 2000
      });
    }
  };

  __onGetDetail = ({ productId }) => {
    this._updateState({ isProcessing: true });

    getProdutDetail(this._state.productList, productId)
      .then((data) => {
        this._updateState({ isProcessing: false, ...data });
      });
  };

  __onChangeName = ({ newName }) => {
    if (this._state.productId) {
      changeProductName(this._state.productId, newName)
        .then((data) => {
          if (data.productName) {
            const product = this._state.productList.find(({ productId }) => (
              productId === this._state.productId
            ));
            product.productName = newName;
          }

          this._updateState({ ...data });
        });
    }
  };

  __onAddStatement = ({ statementDate }) => {
    if (this._state.productId) {
      this._updateState({ isProcessing: true });
      addStatement(this._state.productId, this._state.statements ?? [], statementDate)
        .then((data) => {
          const { statements } = data;
          const currentProduct = this._state.productList.find((val) => (
            val.productId === this._state.productId
          ));
          currentProduct.statements = deepCopy(statements);
          this._updateState({ isProcessing: false, ...data });
        });
    }
  };

  __onAddCost = ({ costType, costData }) => {
    if (this._state.currentStatement?.[costType]) {
      const { statementId } = this._state.currentStatement;
      const newCostData = { ...costData, id: `${statementId}_${generateUUID()}` };
      this._updateState({ isProcessing: true });
      addCost(statementId, costType, newCostData)
        .then((data) => {
          if (!data.productDetailError) {
            this._state.currentStatement[costType].push(newCostData);
            this._updateState({ isProcessing: false, productDetailError: 0 });
          } else {
            this._updateState({ ...data, isProcessing: false });
          }
        });
    }
  };

  __onUpdateCost = ({ costType, costId, costData }) => {
    if (this._state.currentStatement?.[costType]) {
      const { statementId } = this._state.currentStatement;
      this._updateState({ isProcessing: true });
      updateCost(statementId, costType, costId, costData)
        .then((data) => {
          if (data.newData) {
            this._state.currentStatement[costType] = data.newData;
            this._updateState({ isProcessing: false });
          } else {
            this._updateState({ ...data, isProcessing: false });
          }
        });
    }
  };

  __onUpdateSale = ({ saleData }) => {
    const { statementId } = this._state.currentStatement;
    this._updateState({ isProcessing: true });
    updateSale(statementId, saleData)
      .then((data) => {
        if (!data.productDetailError) {
          this._state.currentStatement = { ...this._state.currentStatement, ...saleData };
          this._updateState({ isProcessing: false, productDetailError: 0 });
        } else {
          this._updateState({ ...data, isProcessing: false });
        }
      });
  };

  __onSelectStatement = ({ statementId }) => {
    this._updateState({ isProcessing: true });
    getStatement(statementId)
      .then((data) => {
        if (!data.productDetailError) {
          const currentStatement = data;
          currentStatement.statementId = statementId;
          this._updateState({ currentStatement, isProcessing: false });
        } else {
          this._updateState({ ...data, isProcessing: false });
        }
      });
  };

  __onDeleteStatement = () => {
    this._updateState({ isProcessing: true });

    const { productId, statements, currentStatement } = this._state;
    const currentStatementId = currentStatement.statementId;
    let nextStatementId;

    const statementIndex = statements.findIndex((val) => val.id === currentStatementId);
    const currentStatementData = statements[statementIndex];
    if (statements.length > 1) {
      nextStatementId = statements[statementIndex - 1]?.id ?? statements[statementIndex + 1]?.id;
    }

    deleteStatement(productId, currentStatementId, currentStatementData, nextStatementId)
      .then((data) => {
        const currentProduct = this._state.productList.find((val) => (
          val.productId === productId
        ));
        currentProduct.statements = currentProduct.statements.filter((val) => (
          val.id !== currentStatementId
        ));

        this._updateState({
          isProcessing: false,
          statements: currentProduct.statements.slice(),
          ...data
        });
      });
  };

  __onDeleteProduct = () => {
    const { productId, statements } = this._state;
    if (!productId) return;

    this._updateState({ isProcessing: true });
    deleteProduct(productId, statements)
      .then((data) => {
        if (!data.productDetailError) {
          const newProductList = this._state.productList.filter((product) => (
            product.productId !== productId
          ));
          this._updateState({
            isProcessing: false,
            productId: undefined,
            statements: undefined,
            currentStatement: undefined,
            productList: newProductList,
            hasDeletedProduct: true
          });

          this._state.hasDeletedProduct = false;
        } else {
          this._updateState({ ...data, isProcessing: false });
        }
      });
  };
}

const productStore = new ProductStore();
export default productStore;
